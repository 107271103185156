import { initializeApp, } from 'firebase/app';
import {
    collection, collectionGroup, doc, setDoc, updateDoc, getDoc, getDocs, query, where, FieldPath, QueryFieldFilterConstraint, QueryOrderByConstraint,
    deleteDoc, writeBatch, WriteBatch, DocumentReference, CollectionReference, DocumentSnapshot, Query, QuerySnapshot, Transaction, runTransaction,
    getFirestore, DocumentData, WhereFilterOp, orderBy
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';
import axios from 'axios';
import Mixpanel from 'mixpanel-browser';
import { randomString, dbgLog } from './utility';
import { withoutProgress } from './FunctionPromise';
import { FbConfig, MxpConfig, Urls, SRV_TRCK, VERSION } from './config';
import { Entity, toEntity } from 'src/types/EventTypes';
export { type Entity, toEntity } from 'src/types/EventTypes';

export {
    collection, collectionGroup, doc, setDoc, updateDoc, getDoc, getDocs, query, where, FieldPath, QueryFieldFilterConstraint, QueryOrderByConstraint,
    deleteDoc, writeBatch, WriteBatch, DocumentReference, CollectionReference, DocumentSnapshot, QuerySnapshot, Transaction, runTransaction,
    getCountFromServer, Query, onSnapshot, orderBy, limit, startAfter, limitToLast, endBefore, documentId, QueryConstraint, FieldValue, deleteField,
    type DocumentData
} from 'firebase/firestore';

export { signInWithCustomToken, getAdditionalUserInfo, type User } from 'firebase/auth';
export { ref, uploadBytes, getDownloadURL, type StorageReference } from 'firebase/storage';

const firebaseApp = initializeApp(FbConfig);

const db = getFirestore(firebaseApp);

export const firebaseAuth = getAuth(firebaseApp);

const firebaseAnalytics = getAnalytics();

const storage = getStorage();
storage.maxUploadRetryTime = 10000;

export const avatarsRef = ref(storage, 'avatars');

export const portalImagesRef = ref(storage, 'portal-users');

export const templateGolferListRef = ref(storage, 'portal-default/golfer_list_template_v3.xlsx');

export const dbCollection = (coll: string, collGroup?: boolean) => collGroup ? collectionGroup(db, coll) : collection(db, coll);

export const usersDb = collection(db, '/users');
export const userFields = (userId: string) => doc(db, 'users', userId);
export const userPubFields = (userId: string) => doc(db, 'users-pub', userId);

export const eventPath = (eventId: string) => `/events/${eventId}`;
export const eventsDb = collection(db, `/events`);
export const eventFields = (eventId: string) => doc(db, `events`, eventId);

export const eventMappingPath = `/event-mapping`;
export const eventMappingDb = collection(db, eventMappingPath);

export const eventInviteMappingPath = `/event-invite-mapping`;
export const eventInviteMappingDb = collection(db, eventInviteMappingPath);

export const portalFields = (eventId: string) => doc(db, `portals`, eventId);
export const portalInfoDb = collection(db, `/portals`);

export const recentCoursesDb = (userId: string) => collection(db, `/users/${userId}/recent-courses`);
export const coursesDb = (userId: string) => collection(db, `/users/${userId}/courses`);
export const rosterDb = (userId: string) => collection(db, `/users/${userId}/roster`);

export const eventGolfersDb = collection(db, '/event-golfers');
export const golferDb = (eventId: string) => collection(db, `/event-golfers/${eventId}/contacts`);
export const golferGroupDb = (eventId: string) => collection(db, `/event-golfers/${eventId}/groups`);
export const golferTeamDb = (eventId: string) => collection(db, `/event-golfers/${eventId}/teams`);
export const golferInvitesDb = (eventId: string) => collection(db, `/event-golfers/${eventId}/invites`);

export const eventScoresDb = collection(db, '/event-scores');
export const golferScoresDb = (eventId: string) => collection(db, `/event-scores/${eventId}/scores`);
export const golferTeamScoresDb = (eventId: string) => collection(db, `/event-scores/${eventId}/team-scores`);
export const golferDistancesDb = (eventId: string) => collection(db, `/event-scores/${eventId}/distances`);

export const reportedScoresDb = collection(db, '/reported-scores');
export const reportedGolferScoresDb = (eventId: string) => collection(db, `/reported-scores/${eventId}/reported-scores`);
export const reportedTeamScoresDb = (eventId: string) => collection(db, `/reported-scores/${eventId}/team-scores`);

export const eventCompetitionsDb = collection(db, '/event-competitions');
export const competitionsDb = (eventId: string) => collection(db, `/event-competitions/${eventId}/competitions`);

export const logsDb = collection(db, '/event-logs');
export const eventLogsDb = (eventId: string) => collection(db, `/event-logs/${eventId}/logs`);
export const adminLogsDb = () => collectionGroup(db, `logs`);

export const userImgDb = (userId: string) => collection(db, `/users/${userId}/images`);

export const defaultImgDb = collection(db, `/images`);

export const accessDb = collection(db, '/access_granted');

export const errorsDb = collection(db, `/errors`);
export const errorPath = (id: string) => `/errors/${id}`;

export const eventStatsDb = collection(db, '/event-stats');

export const userStatsDb = collection(db, '/user-stats');

export const acceptedInvitesCollection = (userId: string) => collection(db, `/user-invites/${userId}/accepted-invites`);

export const inviteCodesDb = collection(db, '/invite-codes');
export const eventPaymentsDb = (eventId: string) => collection(db, `/event-payments/${eventId}/payments`);
export const eventPaymentDoc = (eventId: string, paymentId: string) => doc(db, `/event-payments/${eventId}/payments/${paymentId}`);

export const proExpirationDb = collection(db, '/pro');
export const proExpirationDoc = (userId: string) => doc(db, `/pro/${userId}`);

export const mainAnnouncementDoc = () => doc(db, 'announcements', 'main_announcement');

export const eventsQuery = (userId: string) => query(eventsDb, where('userId', '==', userId));
// unused, needs index: export const deletedEventsQuery = (userId: string) => query(eventsDb, where('userId', '==', userId), where('deleted', '==', true));
export const eventRoundsQuery = (eventId: string) => query(eventsDb, where('eventId', '==', eventId));
export const eventGolfersQuery = (eventId: string) => query(golferDb(eventId), where('hidden', '==', false));
export const eventInvitedQuery = (eventId: string) => query(golferInvitesDb(eventId), where('hidden', '==', false));
export const eventTeamsQuery = (eventId: string) => query(golferTeamDb(eventId), orderBy('order'));
export const eventGroupsQuery = (eventId: string) => query(golferGroupDb(eventId), orderBy('order'));
export const eventNonemptyGroupsQuery = (eventId: string) => query(golferGroupDb(eventId), where('contactIds', '!=', []));
export const eventCompetitionsQuery = (eventId: string) => query(competitionsDb(eventId), orderBy('order'));
/// export const golferScoresDb = (eventId: string) => collection(db, `/event-scores/${eventId}/scores`);
/// export const eventContactsQuery = (eventId: string, golferIds: Array<string>) => query(golferDb(eventId), orderBy('order'));
/// export const deletedEventsQuery = (userId: string) => query(eventsDb, where('userId', '==', userId), where('deleted', '==', true));

export type ImageType = 'badge' | 'banner';

export const getDefaultImgDb = (imageType: ImageType) => query(defaultImgDb, where('type', '==', imageType));
export const getUserImgDb = (imageType: ImageType, userId: string) => query(userImgDb(userId), where('type', '==', imageType));

export const referrerDb = collection(db, '/referrers');
export const referrerFields = (userId: string) => doc(db, 'referrers', userId);

export type BatchCallBack = (batch: WriteBatch, docId: string) => Promise<void>;

export interface ImageSrc extends Entity {
    url: string;
    type: ImageType;
}

export function fromEntity<T extends Entity>(doc: DocumentSnapshot): T {
    return {
        ...doc.data(),
        exists: doc.exists(),
        id: doc.id,
        parentId: doc.ref.parent.parent?.id
    } as T;
}

export function toArray<T extends Entity>(snapshot: QuerySnapshot): Array<T> {
    return snapshot.docs.map(doc => fromEntity(doc));
}

export function toMap<T extends Entity>(snapshot: QuerySnapshot): Map<string, T> {
    return new Map<string, T>(snapshot.docs.map(doc => [doc.id, fromEntity<T>(doc)]));
}

export function getDocument(path: string | CollectionReference | DocumentReference, id?: string) {
    if (path instanceof DocumentReference) {
        return getDoc(path);
    } else if (path instanceof CollectionReference) {
        return getDoc(doc(path, id));
    } else if (id) {
        return getDoc(doc(db, path, id));
    } else {
        return getDoc(doc(db, path));
    }
}

export function deleteDocument(path: string | CollectionReference, id: string) {
    if (path instanceof CollectionReference) {
        return deleteDoc(doc(path, id));
    } else {
        return deleteDoc(doc(db, path, id));
    }
}

export async function getEntity<T extends Entity>(path: string | CollectionReference | DocumentReference, id?: string) {
    const docSnap = await getDocument(path, id);
    return fromEntity<T>(docSnap);
}

export interface QueryCondition {
    fieldPath: string | FieldPath;
    opStr: WhereFilterOp;
    value: unknown;
}

export async function getEntities<T extends Entity>(collection: Query, ...conditions: Array<QueryCondition | QueryFieldFilterConstraint | QueryOrderByConstraint>) {
    const constraints = conditions?.map(c => c instanceof QueryFieldFilterConstraint ? c : c instanceof QueryOrderByConstraint ? c : where(c.fieldPath, c.opStr, c.value)) ?? [];
    const q = query(collection, ...constraints);
    const querySnapshot = await getDocs(q);
    return toArray<T>(querySnapshot);
}

export async function mapEntities<T extends Entity>(collection: Query, ...conditions: Array<QueryCondition | QueryFieldFilterConstraint | QueryOrderByConstraint>) {
    const entities = await getEntities<T>(collection, ...conditions);
    return new Map<string, T>(entities.map(entity => [entity.id, entity]));
}

export function updateInTransaction<T extends Entity>(collection: CollectionReference, data: T, transaction: Transaction) {
    const ref = data.id ? doc(collection, data.id) : doc(collection);
    const entity = toEntity(data);
    transaction.update(ref, entity);
    return ref.id;
}

export function setInTransaction<T extends Entity>(collection: CollectionReference, data: T, transaction: Transaction) {
    const ref = data.id ? doc(collection, data.id) : doc(collection);
    const entity = toEntity(data);
    transaction.set(ref, entity);
    return ref.id;
}

export function errMsg(err?: any) {
    return err && err.message ? (': ' + err.message) : (err ? ': ' + err : '');
}

export function getBatch() {
    return writeBatch(db);
}

export function updateOrAddInTransaction<T extends Entity>(collection: CollectionReference, data: T, overwrite: boolean, transaction: Transaction) {
    const ref = data.id ? doc(collection, data.id) : doc(collection);
    const entity = toEntity(data);
    if (!data.id || !data.exists || overwrite) {
        transaction.set(ref, entity);
    } else {
        transaction.update(ref, entity);
    }
    return ref.id;
}

export function setWithMergeInTransaction<T extends Partial<DocumentData>>(collection: CollectionReference, data: T, transaction: Transaction, id?: string) {
    const docRef = id ? doc(collection, id) : data.id ? doc(collection, data.id) : doc(collection);
    transaction.set(docRef, data, { merge: true });
    return docRef.id;
}

export function deleteInTransaction(collection: CollectionReference, dataId: string, transaction: Transaction) {
    const ref = doc(collection, dataId);
    transaction.delete(ref);
}

export function updateOrAddPromise<T extends Entity>(collection: CollectionReference, data: T, overwrite: boolean = false): Promise<string> {
    const entity = toEntity(data);
    const ref = data.id ? doc(collection, data.id) : doc(collection);
    const promise = (!data.id || !data.exists || overwrite) ? setDoc(ref, entity) : updateDoc(ref, entity);
    return new Promise<string>((resolve, reject) => promise.then(() => resolve(ref.id), (reason: any) => reject(reason)));
}

export function setWithMergePromise<T extends Partial<DocumentData>>(collection: CollectionReference, data: T, id?: string): Promise<string> {
    const ref = id ? doc(collection, id) : data.id ? doc(collection, data.id) : doc(collection);
    return new Promise<string>((resolve, reject) => setDoc(ref, data, { merge: true }).then(() => resolve(ref.id), (error: any) => reject('Error' + errMsg(error))));
}

export function updateOrAdd<T extends Entity>(collection: CollectionReference, data: T, overwrite: boolean = false): Promise<string> {
    const errorMessage = (!data.id || !data.exists || overwrite) ? 'Saving failed' : 'Updating failed';
    const promise = updateOrAddPromise(collection, data, overwrite);
    return withoutProgress(promise, errorMessage);
}

export function updatePromise<T extends Entity>(collection: CollectionReference, data: T): Promise<string> {
    const entity = toEntity(data);
    const ref = doc(collection, data.id);
    const promise = updateDoc(ref, entity);
    return new Promise<string>((resolve, reject) => promise.then(() => resolve(ref.id), (reason: any) => reject(reason)));
}

export function update<T extends Entity>(collection: CollectionReference, data: T): Promise<string> {
    const promise = updatePromise(collection, data);
    return withoutProgress(promise, 'Updating failed');
}

export async function updateOrAddAndRemoveBatchPromise<T extends Entity>(collection: CollectionReference, dataAdd: Array<T>, overwrite: boolean, dataRemove: Array<T>, onRemoveCb?: BatchCallBack): Promise<string[]> {
    const batch = getBatch();
    const refs: Array<DocumentReference> = [];
    dataAdd.forEach(item => {
        const entity = toEntity(item);
        const ref = item.id ? doc(collection, item.id) : doc(collection);
        if (!item.id || !item.exists || overwrite) {
            batch.set(ref, entity);
        } else {
            batch.update(ref, entity);
        }
        refs.push(ref);
    });
    for (const elem of dataRemove) {
        if (elem.id) {
            if (onRemoveCb) {
                await onRemoveCb(batch, elem.id);
            }
            batch.delete(doc(collection, elem.id));
        }
    }
    return new Promise<string[]>((resolve, reject) => batch.commit()
        .then(() => resolve(refs.map(ref => ref.id)), (reason: any) => reject(reason)));
}

export function updateOrAddAndRemoveBatch<T extends Entity>(collection: CollectionReference, data: Array<T>, overwrite: boolean, dataToDel: Array<T>, onRemoveCb?: BatchCallBack): Promise<string[]> {
    return withoutProgress(updateOrAddAndRemoveBatchPromise(collection, data, overwrite, dataToDel, onRemoveCb));
}

export function updateOrAddBatchPromise<T extends Entity>(collection: CollectionReference, data: Array<T>, overwrite: boolean = false): Promise<string[]> {
    return updateOrAddAndRemoveBatchPromise(collection, data, overwrite, []);
}

export function updateOrAddBatch<T extends Entity>(collection: CollectionReference, data: Array<T>, overwrite: boolean = false): Promise<string[]> {
    return withoutProgress(updateOrAddBatchPromise(collection, data, overwrite));
}

export function remove<T extends Entity>(collection: CollectionReference, data: T): Promise<void> {
    if (data.id) {
        return withoutProgress(deleteDoc(doc(collection, data.id)), 'Removing failed');
    }
    return Promise.resolve();
}

export async function removeBatchPromise<T extends Entity>(collection: CollectionReference, data: Array<T>, onRemoveCb?: BatchCallBack): Promise<void> {
    const batch = getBatch();
    for (const elem of data) {
        if (elem.id) {
            if (onRemoveCb) {
                await onRemoveCb(batch, elem.id);
            }
            batch.delete(doc(collection, elem.id));
        }
    }
    return batch.commit();
}

export function removeBatch<T extends Entity>(collection: CollectionReference, data: Array<T>, onRemoveCb?: BatchCallBack): Promise<void> {
    return withoutProgress(removeBatchPromise(collection, data, onRemoveCb));
}

export async function removeDocs(refs: Array<DocumentReference>) {
    const batch = getBatch();
    refs.forEach(ref => batch.delete(ref));
    return batch.commit();
}

export async function removeAddBatchPromise<T extends Entity>(collection: CollectionReference, dataRemove: Array<T>, dataAdd: Array<T>, onRemoveCb?: BatchCallBack): Promise<void> {
    const batch = getBatch();
    for (const elem of dataRemove) {
        if (elem.id) {
            if (!!onRemoveCb) {
                await onRemoveCb(batch, elem.id);
            }
            batch.delete(doc(collection, elem.id));
        }
    }
    dataAdd.forEach(elem => batch.set(elem.id ? doc(collection, elem.id) : doc(collection), toEntity(elem)));
    return batch.commit();
}

export function withTransaction<T>(action: (transaction: Transaction) => Promise<T>): Promise<T> {
    return runTransaction(db, transaction => action(transaction));
}

export function getRefs(coll: CollectionReference, promises: Array<Promise<void>>, refs: Array<DocumentReference>) {
    promises.push(getDocs(coll).then(snapshot => snapshot.forEach(elem => refs.push(elem.ref))));
}

export async function getFreePubId(): Promise<string> {
    const publicId = randomString(5);
    const docSnap = await getDocument(eventMappingPath, publicId);
    return await (docSnap.exists() ? getFreePubId() : Promise.resolve(publicId));
}

export function setUserDataPromise(data: DocumentData): Promise<void> {
    if (firebaseAuth.currentUser) {
        return setDoc(userFields(firebaseAuth.currentUser.uid), data, { merge: true });
    } else {
        return Promise.reject('Not logged in');
    }
}

export function setUserData(data: DocumentData, error?: string | boolean, ok?: string): Promise<void> {
    return withoutProgress(setUserDataPromise(data), error, ok);
}

export function setUserPubDataPromise(data: DocumentData): Promise<void> {
    if (firebaseAuth.currentUser) {
        return setDoc(userPubFields(firebaseAuth.currentUser.uid), data, { merge: true });
    } else {
        return Promise.reject('Not logged in');
    }
}

export function setUserPubData(data: DocumentData, error?: string | boolean, ok?: string): Promise<void> {
    return withoutProgress(setUserPubDataPromise(data), error, ok);
}

export async function getUserData(): Promise<DocumentData | undefined> {
    if (firebaseAuth.currentUser) {
        const currentUser = firebaseAuth.currentUser;
        const userDoc = await getDoc(userFields(currentUser.uid));
        return userDoc.exists() ? userDoc.data() : undefined;
    } else {
        return Promise.resolve(undefined);
    }
}

export async function checkPublicEventId(eventId: string): Promise<string> {
    const docSnap = await getDoc(doc(eventMappingDb, eventId));
    if (docSnap.exists() && docSnap.data()!.eventId) {
        const eventDoc = await getDoc(doc(eventsDb, docSnap.data()!.eventId));
        if (eventDoc.exists() && !eventDoc.data()!.deleted) {
            return Promise.resolve('Event ' + eventId + ' exists: ' + docSnap.data()!.eventId);
        } else {
            return Promise.reject('Event ' + eventId + ' not found!');
        }
    } else {
        return Promise.reject('Event ' + eventId + ' not found!');
    }
}

const mixpanel = Mixpanel.init(MxpConfig.token, MxpConfig.config, 'events');

type AnyProps = { [key: string]: any; };

export function srvTrk(event: string, eventProps?: AnyProps) {
    const deviceId = mixpanel.get_property('$device_id');
    const userId = firebaseAuth.currentUser?.uid;
    if (!userId) {
        dbgLog(`[srvTrk] ${deviceId} -- ${userId} SKIP`);
        return;
    }
    const distinctId = userId;
    const msg = {
        event,
        bro: {
            userAgent: navigator.userAgent,
            vendor: (navigator as any).vendor ?? '',
            opera: Boolean((window as any).opera)
        },
        properties: {
            app_id: `events-web-${VERSION}`,
            distinctId,
            deviceId,
            $current_url: window.location.href
        } as AnyProps
    };
    if (eventProps) {
        Object.assign(msg.properties, eventProps);
    }
    dbgLog(`[srvTrk] ${distinctId} - ${event}`);
    axios.post(Urls.checkAction, msg).catch((err: any) => dbgLog('checkAction error', err));
}

export function trackEvent(event: string, eventProps: AnyProps = []) {
    dbgLog(`[trackEvent] ...`);
    logEvent(firebaseAnalytics, 'events_' + event);
    if (event === 'top_get_started' || event.endsWith('_x') || !SRV_TRCK) {
        const distinctId = mixpanel.get_distinct_id();
        const props = {
            app_id: `events-web-${VERSION}`,
            distinctId
        } as AnyProps;
        Object.assign(eventProps, props);
        dbgLog(`[srvTrk] ${distinctId} - ${event}`);
        mixpanel.track(event, eventProps);
    } else {
        srvTrk(event, eventProps);
    }
}
