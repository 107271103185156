import * as React from 'react';
import { Event, User } from "../types/EventTypes";
import DataTable, { Alignment, TableColumn } from "react-data-table-component";
import AppButton from "../common/components/AppButton";
import { EventUserInfo, dataTableStyle } from "./UserList";
import { EventIntroducedGolfersInfo, getAdminAppReportInfo } from "../event/Event";
import { showAlertProps, showProgress } from "../redux/ReduxConfig";
import { XSMobileDialog } from 'src/common/dialog/MobileDialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from "../styles";
import axios from "axios";
import { Urls } from "../util/config";
import * as Backend from "../util/firebase";
import { errMsg } from "../util/firebase";
import { formatDateUS } from "../util/utility";
import moment from "moment";

const adminTableColumns: TableColumn<EventUserInfo>[] = [
    {
        name: 'Id',
        selector: row => row.userId,
        center: true,
        width: '18%'
    },
    {
        name: 'Email',
        selector: row => row.userEmail ?? '',
        center: true
    },
    {
        name: 'Name',
        selector: row => row.userName ?? '',
        center: true
    },
    {
        name: 'Legit events',
        selector: row => row.legitEventsCount,
        center: true,
        width: '12%'
    },
    {
        name: 'Created events',
        selector: row => row.createdEventsCount,
        center: true,
        width: '12%'
    },
    {
        name: 'App reports',
        cell: row => <AppButton color="info" style={{ maxHeight: 26 }} onClick={() => showAppReport(row)}>
            App report
        </AppButton>,
        center: true,
        width: '12%'
    }
];

type AdminReportComponentProps = {
    userInfo: EventUserInfo;
    reportInfo: EventIntroducedGolfersInfo;
    user?: User;
} & WithStyles<typeof styles>;

const AdminReportComponent = withStyles(styles)(({ classes, userInfo, reportInfo, user }: AdminReportComponentProps) => {
    const { userId, userEmail, userName } = userInfo;
    const { acceptedInvitesGolfersAmount, eventsIntroducedGolfersAmount, eventsIntroducedPremiumGolfersAmount,
        firstInviteAcceptanceDate, lastInviteAcceptanceDate } = reportInfo;
    const notDefinedStr = 'Not defined';
    let accountLifetime = notDefinedStr;
    if (user?.creationTime) {
        const diffDuration = moment.duration(moment(Date.now()).diff(moment(user.creationTime)));
        accountLifetime = `${diffDuration.years()} years, ${diffDuration.months()} months`;
    }
    return <div className={classes.sansSerifNormalBlack} style={{ textAlign: 'center' }}>
        <h4>{`Report for user: ${userId}`}</h4>
        <table>
            <tbody>
                <tr>
                    <td className={`${classes.bold09rem} ${classes.minWidth270}`}>User name</td>
                    <td className={classes.minWidth230}>{userName ?? notDefinedStr}</td>
                </tr>
                <tr>
                    <td className={`${classes.bold09rem} ${classes.minWidth270}`}>User email</td>
                    <td className={classes.minWidth230}>{userEmail ?? notDefinedStr}</td>
                </tr>
                <tr>
                    <td className={`${classes.bold09rem} ${classes.minWidth270}`}>Account lifetime</td>
                    <td className={classes.minWidth230}>{accountLifetime}</td>
                </tr>
                <tr>
                    <td className={`${classes.bold09rem} ${classes.minWidth270}`}>Account created</td>
                    <td className={classes.minWidth230}>{user?.creationTime ? formatDateUS(user.creationTime) : notDefinedStr}</td>
                </tr>
                <tr>
                    <td className={`${classes.bold09rem} ${classes.minWidth270}`}>Last sign in</td>
                    <td className={classes.minWidth230}>{user?.lastSignInTime ? formatDateUS(user.lastSignInTime) : notDefinedStr}</td>
                </tr>
                <tr>
                    <td className={`${classes.bold09rem} ${classes.minWidth270}`}>First invite accepted</td>
                    <td className={classes.minWidth230}>{firstInviteAcceptanceDate ? formatDateUS(firstInviteAcceptanceDate) : notDefinedStr}</td>
                </tr>
                <tr>
                    <td className={`${classes.bold09rem} ${classes.minWidth270}`}>Last invite accepted</td>
                    <td className={classes.minWidth230}>{lastInviteAcceptanceDate ? formatDateUS(lastInviteAcceptanceDate) : notDefinedStr}</td>
                </tr>
                <tr>
                    <td className={`${classes.bold09rem} ${classes.minWidth270}`}>Accepted invites golfers</td>
                    <td className={classes.minWidth230}>{acceptedInvitesGolfersAmount}</td>
                </tr>
                <tr>
                    <td className={`${classes.bold09rem} ${classes.minWidth270}`}>Events introduced golfers</td>
                    <td className={classes.minWidth230}>{eventsIntroducedGolfersAmount}</td>
                </tr>
                <tr>
                    <td className={`${classes.bold09rem} ${classes.minWidth270}`}>Events introduced premium golfers</td>
                    <td className={classes.minWidth230}>{eventsIntroducedPremiumGolfersAmount}</td>
                </tr>
            </tbody>
        </table>
    </div>
});

const showAppReport = async (userInfo: EventUserInfo) => {
    const hideProgress = showProgress('showAppReport');
    const reportInfo = await getAdminAppReportInfo(userInfo.userId, hideProgress);
    if (reportInfo) {
        try {
            let user: User | undefined = undefined;
            if (Backend.firebaseAuth.currentUser) {
                const token = await Backend.firebaseAuth.currentUser.getIdToken(false);
                user = (await axios.post(Urls.findUserById, { token, userId: userInfo.userId })).data;
            }
            hideProgress();
            showAlertProps({
                content: <AdminReportComponent userInfo={userInfo} reportInfo={reportInfo} user={user} />,
                maxWidth: 'lg'
            });
        } catch (err) {
            hideProgress(errMsg(err));
        }
    }
};

export type DateRangeStatisticsInfo = {
    adminsData: Map<string, EventUserInfo>,
    legitEventsAmount: number,
    events: Event[]
};

type DateRangeStatisticsDialogProps = {
    info: DateRangeStatisticsInfo,
    handleClose: () => void
};

export const RangeStatisticsContents = (props: DateRangeStatisticsInfo & WithStyles<typeof styles>) => {
    const { classes, adminsData, legitEventsAmount, events } = props;
    return (
        <span className={`${classes.mainAlertSpan} ${classes.flexColumnContainer}`} style={{ margin: '8px 12px 0px' }}>
            <span>Active admins amount: <span className={classes.alertText}>{adminsData.size}</span>, legit events amount: <span
                className={classes.alertText}>{legitEventsAmount}</span> out of {events.length}.</span>
            <span>'Legit' is a definition of a real non-testing, non-Leaderboard event with at least one golfer and scores added.</span>
            <span>Created events amount includes Live Leaderboards and events with lack of competitions/scores.</span>
            <h2 style={{ textAlign: 'center' }}>Active admins</h2>
            <DataTable
                fixedHeaderScrollHeight={'800px'}
                customStyles={dataTableStyle}
                columns={adminTableColumns}
                data={Array.from<EventUserInfo>(adminsData.values())}
                dense
                fixedHeader={true}
                subHeaderAlign={Alignment.CENTER} />
        </span>
    );
};

const DateRangeStatisticsDialog = withStyles(styles)((props: DateRangeStatisticsDialogProps & WithStyles<typeof styles>) => {
    const { classes, info, handleClose } = props;
    return (
        <XSMobileDialog open={true} onClose={handleClose} maxWidth={'xl'} fullWidth={true}>
            <RangeStatisticsContents classes={classes} {...info} />
        </XSMobileDialog>
    );
});

export default DateRangeStatisticsDialog;
