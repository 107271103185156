import * as React from 'react';
import { DialogContent, DialogActions, FormControl, InputLabel } from '@mui/material';
import { GridSize } from '@mui/material/Grid';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import * as Backend from '../../../util/firebase';
import * as Utils from '../../../util/utility';
import { processEnterKey } from '../../../util/react_utils';
import withForm, { ValidateProps } from '../../../validation/ValidatedForm';
import { SimpleCourse, Gender, Tee, Par, HandicapSystem, MAX_HOLES, NINE_HOLES, HOLES_9, HOLES_18, EventBase, Competition, Contact, Team } from '../../../types/EventTypes';
import { updateGolfersPlayingHandicapInTransaction } from '../../Event';
import { getFrontRating, getBackRating, getFrontSlope, getBackSlope, getRating, correctRating, getSlope, getGender } from '../../../scoring/handicap';
import { SMMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import TypedFormRadioLabel from '../../../common/form/TypedFormRadioLabel';
import TextField, { required, maxLength, posNumber, limitedPosNumber } from '../../../validation/ValidatedTextField';
import { Item, Container, Spacing, FlexLabel } from '../../../common/Misc';
import ConfirmDialog from '../../../common/dialog/ConfirmDialog';
import { ScorecardRow, Edit, KEY_ARROWLEFT, KEY_ARROWRIGHT, KEY_ARROWUP, KEY_ARROWDOWN } from '../scores/EditScoreDialog';
import { styles } from '../../../styles';
import { withProgress } from 'src/util/ProgressPromise';

const MIN_LEN = 50;
const MAX_LEN = 999;
const ratingNumber = limitedPosNumber(10, 40);
const slopeNumber = limitedPosNumber(55, 155);

function isValidHcp(hcp: Array<number>, holeCount: number) {
    const set = new Set();
    for (let i = 0; i < hcp.length; i++) {
        if (hcp[i] && hcp[i] >= 1 && hcp[i] <= holeCount && !set.has(hcp[i])) {
            set.add(hcp[i]);
        } else {
            return false;
        }
    }
    return true;
}

async function saveTees(eventOrRound: EventBase, tees: Array<Tee>, facility: SimpleCourse) {
    const isNine = tees.length > 0 && tees[0].par.length === NINE_HOLES;
    if (eventOrRound.id) {
        const teams = await Backend.mapEntities<Team>(Backend.golferTeamDb(eventOrRound.id));
        const golfers = await Backend.mapEntities<Contact>(Backend.golferDb(eventOrRound.id));
        const competitions = await Backend.getEntities<Competition>(Backend.competitionsDb(eventOrRound.id));
        const course = eventOrRound.course;
        if (course && 'facilityId' in course) {
            if (course.inCourse && course.inCourse.id === facility.id) {
                course.inCourse.tees = tees;
            }
            if (course.outCourse && course.outCourse.id === facility.id) {
                course.outCourse.tees = tees;
            }            
        } else if (course && course.id == facility.id){
            course.tees = tees;
        }
        await Backend.withTransaction(transaction => {
            const time = new Date().getTime();
            Backend.setInTransaction(Backend.coursesDb(eventOrRound.userId), {
                time, isNine,
                id: facility.id,
                name: facility.name,
                tees: cleanTees(tees)
            }, transaction);
            Backend.updateInTransaction(Backend.eventsDb, {
                id: eventOrRound.id,
                teeMen: tees.find(t => t.id === eventOrRound.teeMen?.id) ?? Backend.deleteField(),
                teeWomen: tees.find(t => t.id === eventOrRound.teeWomen?.id) ?? Backend.deleteField()
            }, transaction);
            golfers.forEach(contact => {
                if (contact.tee) {
                    const tee = tees.find(t => t.id === contact.tee?.id);
                    Backend.updateInTransaction(Backend.golferDb(eventOrRound.id), { id: contact.id, tee }, transaction);
                }
            });
            updateGolfersPlayingHandicapInTransaction(transaction, eventOrRound, golfers, teams, competitions);
            return Promise.resolve();
        });
    } else {
        // newly created event
        const time = new Date().getTime();
        await Backend.setWithMergePromise(Backend.coursesDb(eventOrRound.userId), {
            time, isNine,
            id: facility.id,
            name: facility.name,
            tees: cleanTees(tees)
        });
    }
}

type Props = {
    eventOrRound: EventBase;
    facility: SimpleCourse;
    tee: Tee;
    tees: Array<Tee>;
    close: (changed: boolean, id: string, gender: Gender) => void;
    noGenderRequired?: boolean;
    showFacilityName?: boolean;
    isNine?: boolean;
    genderFixed?: boolean;
} & WithStyles<typeof styles> & ValidateProps;

type State = {
    isNew: boolean;
    editedGender: boolean;
    editedHoles: boolean;
    errorHighlighted: boolean;
    name: string;
    gender: Gender;
    ratingFront: string;
    slopeFront: string;
    ratingBack: string;
    slopeBack: string;
    conguSss: string;
    focusedId: string;
    focusedIdx: number;
    handicapSystem: HandicapSystem;
    par: Array<Par>;
    handicap: Array<number>;
    handicap2: Array<number>;
    len: Array<number>;
    confirmingDelete?: () => void;
    confirmingCancel?: () => void;
    isNine: boolean;
};

const Itm = (props: { children?: React.ReactNode | string, pb?: number, xs?: GridSize, width?: number }) => {
    const { children, pb, width } = props;
    return <Item width={width || 240} paddingBottom={pb}>{children}</Item>;
};

function cleanTees(tees: Array<Tee>): Array<Tee> {
    return tees.map(tee => {
        const cleanTee = { ...tee };
        delete cleanTee.facilityId;
        delete cleanTee.facilityName;
        return cleanTee;
    });
}

class EditTeeDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { tees, tee } = props;
        const isNew = !tee.id;
        const copyRef = isNew && Boolean(tee.gender) && !tee.par;
        this.state = {
            isNew,
            editedGender: copyRef,
            editedHoles: false,
            errorHighlighted: false,
            name: tee.name || '',
            handicapSystem: tee.handicapSystem ? tee.handicapSystem : tees.length ? tees[0].handicapSystem : 'WHS',
            gender: isNew ? (tee.gender ?? '') : getGender(tee),
            ratingFront: isNew ? '0' : '' + getFrontRating(tee),
            slopeFront: isNew ? '0' : '' + getFrontSlope(tee),
            ratingBack: isNew ? '0' : '' + getBackRating(tee),
            slopeBack: isNew ? '0' : '' + getBackSlope(tee),
            conguSss: isNew ? '0' : '' + tee.conguSss,
            focusedId: '',
            focusedIdx: 0,
            par: tee.par ? tee.par.slice() : [],
            handicap: tee.handicap ? tee.handicap.slice() : [],
            handicap2: tee.handicap2 ? tee.handicap2.slice()
                .map(val => (val < 19 && val > 0) ? val + 18 : val) : [],
            len: tee.len ? tee.len.slice() : [],
            isNine: props.isNine || (tees.length > 0 && tees[0].par.length === NINE_HOLES)
        };
        if (copyRef) {
            this.copyReferenceTee(this.state);
        }
    }

    private getName = () => Utils.toSafeString(this.state.name, true);

    private getId = () => {
        const { tee } = this.props;
        const { isNew, gender } = this.state;
        if (isNew) {
            return (gender === 'female' ? 'F-' : 'M-') + this.getName().toUpperCase();
        } else {
            return tee.id;
        }
    }

    private handleClose = () => {
        const { close, tee } = this.props;
        const { editedHoles, editedGender, isNew, gender } = this.state;
        const name = this.getName();
        const id = this.getId();
        if (editedHoles || editedGender || (!isNew && name !== tee.name)) {
            this.setState({ confirmingCancel: () => close(false, id, gender) });
        } else {
            close(false, id, gender);
        }
    }

    private handleSave = () => {
        const { tee } = this.props;
        const tees = this.props.tees.slice();
        const {
            ratingFront, slopeFront, ratingBack, slopeBack, conguSss, handicapSystem, par, handicap, handicap2, len, gender, isNine
        } = this.state;
        const name = this.getName();
        const id = this.getId();
        const newTee: Tee = {
            id,
            par,
            len,
            name,
            gender,
            handicap,
            handicap2,
            handicapSystem,
            facilityId: tee.facilityId,
            facilityName: tee.facilityName,
            rating: isNine ? correctRating(parseFloat(ratingFront)) : 0,
            slope: isNine ? correctRating(parseFloat(slopeFront)) : 0,
            ratingFront: isNine ? 0 : correctRating(parseFloat(ratingFront)),
            slopeFront: isNine ? 0 : correctRating(parseFloat(slopeFront)),
            ratingBack: isNine ? 0 : parseFloat(ratingBack),
            slopeBack: isNine ? 0 : parseFloat(slopeBack),
            conguSss: parseFloat(conguSss),
            edited: true
        };
        const pos = tees.findIndex(t => t.id === newTee.id && t.facilityId === newTee.facilityId);
        if (pos >= 0) {
            tees.splice(pos, 1, newTee);
        } else {
            tees.push(newTee);
        }
        this.saveTees(id, tees);
    }

    private handleDeleteTee = () => this.setState({ confirmingDelete: this.handleDelete });

    private handleDelete = () => {
        const { tee, tees } = this.props;
        const dubTee = tees.find(t => t.id === tee.id);
        if (dubTee) {
            dubTee.deleted = true;
            this.setState({ confirmingDelete: undefined }, () => this.saveTees(dubTee.id, tees));
        }
    }

    private saveTees = async (id: string, tees: Array<Tee>) => {
        const { eventOrRound, facility } = this.props;
        const { gender } = this.state;
        tees = tees.filter(t => t.facilityId === facility.id);
        withProgress(saveTees(eventOrRound, tees, facility).then(() => this.props.close(true, id, gender)));
    }

    private copyReferenceTee = (state: State) => {
        const { tees, isNine } = this.props;
        const { gender } = this.state;
        const name = this.getName();
        const id = this.getId();
        let teeRef = tees.find(tee => tee.id !== id && getGender(tee) === gender);
        if (!teeRef) {
            teeRef = tees.find(tee => tee.id !== id && getGender(tee) !== gender);
        }
        if (teeRef && teeRef.par) {
            state.par = isNine ? teeRef.par.slice(0, 9) : teeRef.par.slice();
            if (teeRef.handicap) {
                state.handicap = isNine ? new Array<number>(9).fill(0) : teeRef.handicap.slice();
            }
            if (teeRef.handicap2 && !isNine) {
                state.handicap2 = isNine ? new Array<number>(9).fill(0) : teeRef.handicap2.slice();
            }
            state.handicapSystem = teeRef.handicapSystem;
            state.conguSss = '' + teeRef.conguSss;
            state.ratingFront = '' + getFrontRating(teeRef);
            state.slopeFront = '' + getFrontSlope(teeRef);
            state.ratingBack = '' + getBackRating(teeRef);
            state.slopeBack = '' + getBackSlope(teeRef);
        }
        teeRef = tees.find(tee => tee.id !== id && tee.name.toLocaleLowerCase() === name);
        if (teeRef && teeRef.len) {
            state.len = teeRef.len.slice();
        }
    }

    private handlePropChange = (prop: keyof State, val: string) => {
        if (prop === 'slopeFront' || prop === 'slopeBack') {
            const n = parseInt(val, 10);
            val = '' + (isNaN(n) ? '' : n);
        } else if (prop !== 'name') {
            val = Utils.toSafeString(val, true);
        }
        const s = { focusedId: prop } as any;
        s[prop] = val;
        const state = s as State;
        if (prop === 'gender') {
            state.editedGender = true;
            const { isNew, editedHoles } = this.state;
            if (isNew && !editedHoles) {
                this.copyReferenceTee(state);
            }
        }
        if (prop === 'conguSss' || prop === 'ratingFront' || prop === 'slopeFront' || prop === 'ratingBack' || prop === 'slopeBack') {
            state.editedHoles = true;
        }
        if (prop === 'gender') {
            this.setState(state, () => setTimeout(() => this.setState({ errorHighlighted: !!this.props.validate && !this.props.validate() }), 0));
        } else {
            this.setState(state, () => setTimeout(() => this.setState({ errorHighlighted: !!this.props.errorHighlighted && this.props.errorHighlighted() }), 0));
        }
    }

    validated = () => this.setState({ errorHighlighted: !!this.props.errorHighlighted && this.props.errorHighlighted() });

    private handleFocused = (prop: keyof State, focusedIdx: number) => {
        if (focusedIdx !== this.state.focusedIdx || prop !== this.state.focusedId) {
            this.setState({ focusedIdx, focusedId: prop });
        }
    }

    private handleNumberPropKey = (prop: keyof State, hole: number) => (key: string) => {
        if (key === KEY_ARROWLEFT) {
            // LEFT
            const focusedId = prop;
            const focusedIdx = (hole > 0) ? (hole - 1) : hole;
            this.setState({ focusedId, focusedIdx });
        } else if (key === KEY_ARROWRIGHT) {
            // RIGHT
            const focusedId = prop;
            const focusedIdx = (hole < MAX_HOLES - 1) ? (hole + 1) : hole;
            this.setState({ focusedId, focusedIdx });
        } else if (key === KEY_ARROWUP) {
            // UP
            if (prop === 'len') {
                this.setState({ focusedId: 'handicap2' });
            } else if (prop === 'handicap2') {
                this.setState({ focusedId: 'handicap' });
            } else if (prop === 'handicap') {
                this.setState({ focusedId: 'par' });
            }
        } else if (key === KEY_ARROWDOWN) {
            // DOWN
            if (prop === 'par') {
                this.setState({ focusedId: 'handicap' });
            } else if (prop === 'handicap') {
                this.setState({ focusedId: 'handicap2' });
            } else if (prop === 'handicap2') {
                this.setState({ focusedId: 'len' });
            }
        }
    }

    private handleNumberPropChange = (prop: keyof State, hole: number) => (val?: number) => {
        val = val || 0;
        if (val >= 0) {
            const state = {
                editedHoles: true,
                focusedId: prop,
                focusedIdx: (prop === 'par')
                    ? ((val && val > 1) ? (hole + 1) : hole)
                    : (prop === 'len')
                        ? ((val && val > 99) ? (hole + 1) : hole)
                        : this.state.focusedIdx
            } as any;
            state[prop] = this.state[prop];
            if (!state[prop] || (state[prop] as Array<number>).length === 0) {
                state[prop] = Utils.array(this.props.tees.length > 0 ? this.props.tees[0].par.length : MAX_HOLES, 0);
            }
            state[prop][hole] = val;
            this.setState(state);
        }
    }

    private notInList = {
        valid: (value?: string) => {
            const { tees, tee } = this.props;
            const { gender } = this.state;
            const name = Utils.toSafeString(value, true).toLocaleLowerCase();
            return !gender || !name || !tees.find(t => t.id !== tee.id && t.facilityId === tee.facilityId && t.name.toLocaleLowerCase() === name && getGender(t) === gender);
        },
        text: 'Tee with such name and gender already exists.'
    };

    private editsName = () => {
        const { register, classes } = this.props;
        const { name } = this.state;
        return (
            <React.Fragment>
                <TextField
                    keepError
                    register={register}
                    validated={this.validated}
                    rules={[required, maxLength(30), this.notInList]}
                    className={classes.boldText}
                    textFieldProps={{ id: 'name-input', autoFocus: true, label: 'Tees', value: name, onChange: e => this.handlePropChange('name', e.target.value) }} />
            </React.Fragment>
        );
    }

    private editsGender = () => {
        const { classes, genderFixed } = this.props;
        const { gender } = this.state;
        return (
            <FormControl
                variant="standard"
                margin="dense"
                fullWidth
                style={{ flexDirection: 'row' }}>
                <InputLabel shrink={true}>Gender</InputLabel>
                <TypedFormRadioLabel
                    currentValue={gender}
                    value="male" label="Male"
                    disabled={genderFixed && gender === 'female'}
                    handleChange={(val: string) => this.handlePropChange('gender', val)}
                    className={classes.formSelector} />
                <TypedFormRadioLabel
                    currentValue={gender}
                    value="female" label="Female"
                    disabled={genderFixed && gender === 'male'}
                    handleChange={(val: string) => this.handlePropChange('gender', val)}
                    className={classes.formSelector} />
            </FormControl>
        );
    }

    private editsCONGU = () => {
        const { register } = this.props;
        const { conguSss } = this.state;
        return (
            <Container>
                <Item xs={4}><TextField
                    keepError
                    register={register}
                    validated={this.validated}
                    rules={[required, posNumber]}
                    textFieldProps={{ id: 'congu-input', label: 'Congu SSS', value: conguSss, onChange: e => this.handlePropChange('conguSss', e.target.value) }} /></Item>
            </Container>
        );
    }

    private editsSAGA = () => {
        const { register } = this.props;
        const { ratingFront, ratingBack, isNine } = this.state;
        if (isNine) {
            return (
                <Container>
                    <Item xs={4}><TextField
                        keepError
                        register={register}
                        validated={this.validated}
                        rules={[required, posNumber, ratingNumber]}
                        textFieldProps={{ id: 'saga-input', label: 'Rating', value: ratingFront, onChange: e => this.handlePropChange('ratingFront', e.target.value) }} /></Item>
                </Container>
            );
        } else {
            return (
                <Container>
                    <Item xs={4}><TextField
                        keepError
                        register={register}
                        validated={this.validated}
                        rules={[required, posNumber, ratingNumber]}
                        textFieldProps={{ id: 'saga-input', label: 'Rating Front', value: ratingFront, onChange: e => this.handlePropChange('ratingFront', e.target.value) }} /></Item>
                    <Item xs={4}><TextField
                        keepError
                        register={register}
                        validated={this.validated}
                        rules={[required, posNumber, ratingNumber]}
                        textFieldProps={{ id: 'saga-input2', label: 'Rating Back', value: ratingBack, onChange: e => this.handlePropChange('ratingBack', e.target.value) }} /></Item>
                </Container>
            );
        }
    }

    private editsGA = () => {
        const { eventOrRound, register, classes } = this.props;
        const { ratingFront, ratingBack, slopeFront, slopeBack, isNine } = this.state;
        const rating = getRating({ ratingFront: parseFloat(ratingFront), ratingBack: parseFloat(ratingBack) } as Tee, eventOrRound.holesType!);
        const slope = getSlope({ slopeFront: parseFloat(slopeFront), slopeBack: parseFloat(slopeBack) } as Tee);
        if (isNine) {
            return (
                <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'row' }}>
                    <InputLabel htmlFor="rating-and-slope-input" shrink={true}>Rating and slope (select any cell to edit)</InputLabel>
                    <div id="rating-and-slope-input">
                        <Spacing />
                        <Container spacing={1} alignItems="center" wrap="nowrap">
                            <Itm width={100}>&nbsp;</Itm>
                            <Itm width={160}><InputLabel shrink={true} htmlFor="rating-input">Rating</InputLabel></Itm>
                            <Itm width={160}><InputLabel shrink={true} htmlFor="slope-input">Slope</InputLabel></Itm>
                        </Container>
                        <Container spacing={1} alignItems="center" wrap="nowrap">
                            <Itm width={100}>9 holes</Itm>
                            <Itm width={160}><TextField
                                keepError
                                register={register}
                                validated={this.validated}
                                rules={[required, posNumber, ratingNumber]}
                                textFieldProps={{ id: 'rating-input', margin: 'dense', value: ratingFront, onChange: e => this.handlePropChange('ratingFront', e.target.value) }} /></Itm>
                            <Itm width={160}><TextField
                                keepError
                                register={register}
                                validated={this.validated}
                                rules={[required, posNumber, slopeNumber]}
                                textFieldProps={{ id: 'slope-input', margin: 'dense', value: slopeFront, onChange: e => this.handlePropChange('slopeFront', e.target.value) }} /></Itm>
                        </Container>
                    </div>
                </FormControl>
            );
        } else {
            return (
                <FormControl
                    variant="standard"
                    margin="dense"
                    fullWidth
                    style={{ flexDirection: 'row' }}>
                    <InputLabel htmlFor="rating-and-slope-input" shrink={true}>Rating and slope (select any cell to edit)</InputLabel>
                    <div id="rating-and-slope-input">
                        <Spacing />
                        <Container spacing={1} alignItems="center" wrap="nowrap">
                            <Itm width={100}>&nbsp;</Itm>
                            <Itm><InputLabel shrink={true} htmlFor="rating-input">Rating</InputLabel></Itm>
                            <Itm><InputLabel shrink={true} htmlFor="slope-input">Slope</InputLabel></Itm>
                        </Container>
                        <Container spacing={1} alignItems="center" wrap="nowrap">
                            <Itm width={100}>Front nine</Itm>
                            <Itm><TextField
                                keepError
                                register={register}
                                validated={this.validated}
                                rules={[required, posNumber, ratingNumber]}
                                textFieldProps={{ id: 'rating-input', margin: 'dense', value: ratingFront, onChange: e => this.handlePropChange('ratingFront', e.target.value) }} /></Itm>
                            <Itm><TextField
                                keepError
                                register={register}
                                validated={this.validated}
                                rules={[required, posNumber, slopeNumber]}
                                textFieldProps={{ id: 'slope-input', margin: 'dense', value: slopeFront, onChange: e => this.handlePropChange('slopeFront', e.target.value) }} /></Itm>
                        </Container>
                        <Container spacing={1} alignItems="center" wrap="nowrap">
                            <Itm width={100}>Back nine</Itm>
                            <Itm><TextField
                                keepError
                                register={register}
                                validated={this.validated}
                                rules={[required, posNumber, ratingNumber]}
                                textFieldProps={{ id: 'rating-input2', margin: 'dense', value: ratingBack, onChange: e => this.handlePropChange('ratingBack', e.target.value) }} /></Itm>
                            <Itm><TextField
                                keepError
                                register={register}
                                validated={this.validated}
                                rules={[required, posNumber, slopeNumber]}
                                textFieldProps={{ id: 'slope-input2', margin: 'dense', value: slopeBack, onChange: e => this.handlePropChange('slopeBack', e.target.value) }} /></Itm>
                        </Container>
                        <Container spacing={1} alignItems="center" wrap="nowrap" className={classes.boldText}>
                            <Itm width={100}>18 holes</Itm>
                            <Itm>{!isNaN(rating) ? rating : ''}</Itm>
                            <Itm>{!isNaN(slope) ? slope : ''}</Itm>
                        </Container>
                    </div>
                </FormControl>
            );
        }
    }

    private editsRating = () => {
        const { handicapSystem } = this.state;
        switch (handicapSystem) {
            case 'CONGU':
                return this.editsCONGU();
            case 'SAGA':
                return this.editsSAGA();
            case 'GA':
            default:
                return this.editsGA();
        }
    }

    private editsPars = () => {
        const { classes, eventOrRound } = this.props;
        const { focusedIdx, focusedId, par, handicap, handicap2, len, handicapSystem, isNine } = this.state;
        const holeCount = isNine ? NINE_HOLES : MAX_HOLES;
        const holesType = isNine ? HOLES_9 : HOLES_18;
        const hcpSet = new Set();
        const hcpSetNotUnique = new Set();
        for (let i = 0; i < handicap.length; i++) {
            if (handicap[i]) {
                if (hcpSet.has(handicap[i])) {
                    hcpSetNotUnique.add(handicap[i]);
                }
                hcpSet.add(handicap[i]);
            }
        }
        for (let i = 0; i < handicap2.length; i++) {
            if (handicap2[i]) {
                if (hcpSet.has(handicap2[i])) {
                    hcpSetNotUnique.add(handicap2[i]);
                }
                hcpSet.add(handicap2[i]);
            }
        }
        const subLabelHCP1 = (eventOrRound.handicapSystem !== 'WHS_AU' && (!!eventOrRound.handicapSystem || handicapSystem !== 'WHS_AU')) ? undefined : (isNine ? "1-9" : "1-18");
        const subLabelHCP2 = (eventOrRound.handicapSystem !== 'WHS_AU' && (!!eventOrRound.handicapSystem || handicapSystem !== 'WHS_AU')) ? undefined : (isNine ? "10-18" : "19-36");
        const editHolesGen = (hole: number) => {
            if (hole < 0) {
                return '';
            }
            return <Edit
                trackFocus
                type="PAR"
                value={par[hole]}
                error={!par[hole] || par[hole] < 3 || par[hole] > 5}
                onKey={this.handleNumberPropKey('par', hole)}
                onValue={this.handleNumberPropChange('par', hole)}
                onFocused={() => this.handleFocused('par', hole)}
                focused={hole === focusedIdx && 'par' === focusedId}
            />;
        };
        const hcp18Gen = (hole: number) => {
            if (hole < 0) {
                return '';
            }
            return <Edit
                trackFocus
                type={'HCP18'}
                value={handicap[hole]}
                error={!handicap[hole] || handicap[hole] < 1 || handicap[hole] > holeCount || hcpSetNotUnique.has(handicap[hole])}
                onKey={this.handleNumberPropKey('handicap', hole)}
                onValue={this.handleNumberPropChange('handicap', hole)}
                onFocused={() => this.handleFocused('handicap', hole)}
                focused={hole === focusedIdx && 'handicap' === focusedId}
            />;
        };
        const hcp18Gen2 = (hole: number) => {
            if (hole < 0) {
                return '';
            }
            return <Edit
                trackFocus
                type={'2HCP18'}
                value={handicap2[hole]}
                error={handicap2.filter(n => !!n).length > 0 && (!handicap2[hole] || handicap2[hole] < holeCount || handicap2[hole] > 2 * holeCount || hcpSetNotUnique.has(handicap2[hole]))}
                onKey={this.handleNumberPropKey('handicap2', hole)}
                onValue={this.handleNumberPropChange('handicap2', hole)}
                onFocused={() => this.handleFocused('handicap2', hole)}
                focused={hole === focusedIdx && 'handicap2' === focusedId}
            />;
        };
        const lengthGen = (hole: number) => {
            if (hole < 0) {
                return '';
            }
            return <Edit
                trackFocus
                type="LEN"
                value={len[hole]}
                error={!!len.find(l => !!l) && (!len[hole] || (len[hole] < MIN_LEN || len[hole] > MAX_LEN))}
                onKey={this.handleNumberPropKey('len', hole)}
                onValue={this.handleNumberPropChange('len', hole)}
                onFocused={() => this.handleFocused('len', hole)}
                focused={hole === focusedIdx && 'len' === focusedId}
            />;
        };
        return (
            <FormControl
                variant="standard"
                margin="dense"
                fullWidth
                style={{ flexDirection: 'row' }}>
                <InputLabel htmlFor="scorecard-input" shrink={true}>Details (select any cell to edit)</InputLabel>
                <div id="scorecard-input" className={classes.editscoreScrollPanel}>
                    <Spacing />
                    <div className={isNine ? classes.scorecard9 : classes.scorecard18}>
                        <ScorecardRow
                            label="HOLE"
                            inOutForm={0}
                            holesType={holesType}
                            gen={hole => hole < 0 ? '' : hole + 1}
                            classes={{ row: classes.holeRow }} />
                        <ScorecardRow
                            label="PAR"
                            inOutForm={0}
                            holesType={holesType}
                            classes={{ row: classes.hcpGross, cells: classes.hcpGrossCells }}
                            gen={editHolesGen} />
                        <ScorecardRow
                            label="HCP"
                            inOutForm={0}
                            subLabel={subLabelHCP1}
                            holesType={holesType}
                            classes={{ row: classes.hcpGross, cells: classes.hcpGrossCells }}
                            gen={hcp18Gen} />
                        {(eventOrRound.handicapSystem === 'WHS_AU' || (!eventOrRound.handicapSystem && handicapSystem === 'WHS_AU')) && <ScorecardRow
                            label="HCP"
                            inOutForm={0}
                            subLabel={subLabelHCP2}
                            holesType={holesType}
                            classes={{ row: classes.hcpGross, cells: classes.hcpGrossCells }}
                            gen={hcp18Gen2} />}
                        <ScorecardRow
                            label="LENGTH"
                            inOutForm={0}
                            holesType={holesType}
                            classes={{ row: classes.hcpGross, cells: classes.hcpGrossCells }}
                            gen={lengthGen} />
                    </div>
                </div>
            </FormControl>
        );
    }

    private hcpValid() {
        const { handicap: hcp, isNine } = this.state;
        const holeCount = isNine ? NINE_HOLES : MAX_HOLES;
        return hcp.length === holeCount && isValidHcp(hcp, holeCount);
    }

    private hcp2Valid() {
        const { eventOrRound } = this.props;
        const { handicap2: hcp2, handicapSystem, isNine } = this.state;
        if ((eventOrRound.handicapSystem !== 'WHS_AU' && (!eventOrRound.handicapSystem && handicapSystem !== 'WHS_AU')) || (hcp2.filter(n => !!n).length === 0)) {
            return true;
        }
        const holeCount = isNine ? NINE_HOLES : MAX_HOLES;
        let valid = hcp2.length === holeCount;
        if (valid) {
            const set = new Set();
            for (let i = 0; i < hcp2.length; i++) {
                if (hcp2[i] && hcp2[i] >= holeCount + 1 && hcp2[i] <= 2 * holeCount && !set.has(hcp2[i])) {
                    set.add(hcp2[i]);
                } else {
                    valid = false;
                    break;
                }
            }
        }
        return valid;
    }

    private ratingValid() {
        const { handicapSystem, ratingFront, ratingBack, slopeFront, slopeBack, isNine } = this.state;
        switch (handicapSystem) {
            case 'CONGU':
                const { conguSss } = this.state;
                return posNumber.valid(conguSss);
            case 'SAGA':
                if (isNine)
                    return ratingNumber.valid(ratingFront);
                else
                    return ratingNumber.valid(ratingFront) && ratingNumber.valid(ratingBack);
            case 'GA':
            default:
                if (isNine)
                    return ratingNumber.valid(ratingFront) && slopeNumber.valid(slopeFront);
                else
                    return ratingNumber.valid(ratingFront) && ratingNumber.valid(ratingBack) && slopeNumber.valid(slopeFront) && slopeNumber.valid(slopeBack);
        }
    }

    private parValid() {
        const { par, isNine } = this.state;
        let valid = par.length === (isNine ? NINE_HOLES : MAX_HOLES);
        if (valid) {
            for (let i = 0; i < par.length; i++) {
                if (!(par[i] && par[i] >= 3 && par[i] <= 5)) {
                    valid = false;
                    break;
                }
            }
        }
        return valid;
    }

    private lenValid() {
        const { par, len, isNine } = this.state;
        // check all undefined -> valid
        if (len.find(l => !!l) === undefined) {
            return true;
        }
        // check all defined in range
        let valid = par.length === (isNine ? NINE_HOLES : MAX_HOLES);
        if (valid) {
            for (let i = 0; i < par.length; i++) {
                if (!(len[i] !== undefined && len[i] >= MIN_LEN && len[i] <= MAX_LEN)) {
                    valid = false;
                    break;
                }
            }
        }
        return valid;
    }

    render() {
        const { classes, noGenderRequired, showFacilityName, facility } = this.props;
        const { gender, isNew, editedGender, confirmingDelete, confirmingCancel, isNine } = this.state;
        let { errorHighlighted } = this.state;
        const name = this.getName();
        const hasNameAndGender = !!gender && !!name.trim();
        const showDetails = !isNew || (editedGender || noGenderRequired) || hasNameAndGender;
        let error = '';
        if (!name.trim()) {
            error = 'Missing name!';
        } else if (!gender) {
            error = 'Missing gender!';
        } else if (!this.notInList.valid(name)) {
            error = this.notInList.text;
        } else if (!this.ratingValid()) {
            error = 'Invalid Rating!';
        } else if (!this.parValid()) {
            error = 'Invalid PAR!';
            errorHighlighted = true;
        } else if (!this.hcpValid()) {
            error = 'Invalid HCP!';
            errorHighlighted = true;
        } else if (!this.hcp2Valid()) {
            error = 'Invalid HCP2!';
            errorHighlighted = true;
        } else if (!this.lenValid()) {
            error = 'Invalid LEN!';
            errorHighlighted = true;
        }
        const highlightedError = errorHighlighted ? `Please correct highlighted fields` : '';
        const dialogLabel = `Edit tees${showFacilityName && facility.name ? ` (${facility.name})` : ''}`;
        return (
            <SMMobileDialog open={true} onClose={() => this.handleClose()} maxWidth={'md'} classes={{ paper: isNine ? classes.dialogRoot9 : classes.dialogRoot18 }}
                onKeyDown={e => processEnterKey(e, this.handleSave, !!error || !!confirmingDelete || !!confirmingCancel)}>
                <DialogAppBar label={dialogLabel} close={() => this.handleClose()} />
                <DialogContent>
                    <this.editsName />
                    <this.editsGender />
                    {showDetails && <this.editsRating />}
                    {showDetails && <this.editsPars />}
                </DialogContent>
                <DialogActions>
                    {!isNew && <AppButton onClick={this.handleDeleteTee} color="info">Delete</AppButton>}
                    {showDetails && <span style={{ flex: '1 1 0%', marginRight: 8 }} className={classes.inputError}><FlexLabel
                        placeRight>{highlightedError}</FlexLabel></span>}
                    <AppButton color="info" className={classes.iconButton} onClick={this.handleClose}>Cancel</AppButton>
                    <AppButton color="primary" className={classes.iconButton} onClick={this.handleSave} disabled={!!error}>{isNew ? 'Add tee' : 'Save'}</AppButton>
                </DialogActions>
                {!!confirmingDelete && <ConfirmDialog
                    open={true}
                    disableEscapeKeyDown
                    disableBackdropClick
                    onCancel={() => this.setState({ confirmingDelete: undefined })}
                    onOk={confirmingDelete}
                    title="Confirm permanent delete"
                    content="These tees will be permanently deleted, including all pars, handicaps, lengths, and all other information. This operation can not be un-done. Click CONFIRM to proceed with deletion or CANCEL to keep this tee."
                    okLabel="Confirm and delete" />}
                {!!confirmingCancel && <ConfirmDialog
                    open={true}
                    disableEscapeKeyDown
                    disableBackdropClick
                    onCancel={confirmingCancel}
                    onOk={() => this.setState({ confirmingCancel: undefined })}
                    content="Discard unsaved changes?"
                    cancelLabel="Discard"
                    okLabel="Continue editing" />}
            </SMMobileDialog>
        );
    }
}

export default withStyles(styles)(withForm(EditTeeDialog));
