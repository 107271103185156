import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem, Fab, MenuItemProps, FabProps } from '@mui/material';

interface WithHistory {
    replace?: boolean;
    to: string;
    target?: string;
    onClick?: (event?: any) => void;
}

export function RoutingMenuItem(props: MenuItemProps & WithHistory) {
    const navigate = useNavigate();
    const { onClick, replace, to, ...others } = props as any;
    const { target } = props;
    const handleClick = () => {
        if (!target) {
            navigate(to, { replace });
        }
        if (onClick) {
            onClick();
        }
    }
    return <MenuItem onClick={handleClick} {...others} />
}

export function RoutingButton(props: FabProps & WithHistory) {
    const navigate = useNavigate();
    const { onClick, replace, to, ...others } = props as any;
    const { target } = props;
    const handleClick = () => {
        if (!target) {
            navigate(to, { replace });
        }
        if (onClick) {
            onClick();
        }
    }
    return <Fab onClick={handleClick} {...others} />
}
