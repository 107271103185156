import * as React from 'react';
import { Urls } from 'src/util/config';
import { hideAlert, pushUrl, showAlertProps, showProgress } from 'src/redux/ReduxConfig';
import { getUserInfo, prepareRequest } from 'src/event/Event';
import { UserAware } from './Auth';
import { dbgLog, formatCurrency, formatDateTime, getServerErrorMessage } from 'src/util/utility';
import axios from 'axios';
import { ACTION_CANCEL, ACTION_CHECKOUT, CancelSubscriptionRequest, CancelSubscriptionResponse, CheckoutRequest, CheckoutResponse, ListSubscriptionsRequest, ListSubscriptionsResponse, Subscription, SubscriptionInterval } from 'src/types/EventTypes';
import { XSMobileDialog } from 'src/common/dialog/MobileDialog';
import DialogAppBar from 'src/common/dialog/DialogAppBar';
import AppButton from 'src/common/components/AppButton';
import { DialogActions, DialogContent, Typography } from '@mui/material';

export type AccountActions = 'learn_more' | 'upgrade_yearly' | 'upgrade_mnthly' | 'manage_subscription';

function ManageSubscriptionDialog({ userAware, onClose }: { userAware: UserAware, onClose: VoidFunction }) {
    const [subscriptions, setSubscriptions] = React.useState<Array<Subscription>>([]);
    const [loading, setLoading] = React.useState(0);
    const [error, setError] = React.useState('');
    React.useEffect(() => {
        async function loadSubscription() {
            if (loading > 0) {
                return;
            }
            console.log('[ManageSubscriptionDialog] loadin...');
            setLoading(1);
            setSubscriptions([]);
            const hideProgress = showProgress('signInWithToken');
            try {
                const request: ListSubscriptionsRequest = {
                    token: '',
                    action: '',
                    source: '',
                    userId: userAware.workingUserId!
                };
                await prepareRequest(request);
                const { subscriptions } = (await axios.post(Urls.listSubscriptions, request)).data as ListSubscriptionsResponse;
                setSubscriptions(subscriptions);
                hideProgress();
            } catch (err) {
                hideProgress();
                setError('Failed to load subscription info: ' + getServerErrorMessage(err));
            }
            setLoading(2);
        }
        loadSubscription();
    })
    async function cancelSubscription() {
        await stripeCancel(userAware);
        setSubscriptions([]);
    }
    const active = subscriptions?.find(subscription => subscription.status === 'active');
    return <>
        <XSMobileDialog fullWidth open onClose={onClose} maxWidth={'lg'}>
            <DialogAppBar label={'Manage subscription'} close={onClose} />
            <DialogContent>
                <Typography variant='subtitle1'>Subscription details</Typography>
                <Typography>{error}</Typography>
                {subscriptions.map(subscription =>
                    <div key={subscription.id}>
                        <span style={{ fontWeight: 600 }}>{subscription.name}</span> &nbsp;
                        {formatCurrency(subscription.currency, subscription.price / 100)}/{subscription.interval} &nbsp;
                        {subscription.status !== 'active' &&
                            <span style={{ fontWeight: 300 }}>({subscription.status}) &nbsp;</span>}
                        <br />
                        <Typography>Payments</Typography>
                        {subscription.invoices.map(inv => <span key={inv.id}>
                            {formatDateTime(inv.datePaid)} &nbsp;
                            {formatCurrency(inv.currency, inv.amountPaid / 100)} &nbsp;
                            <a href={inv.receiptUrl} target='_blank'>Receipt</a> <br />
                        </span>)}
                        {subscription.status === 'active' &&
                            <AppButton
                                color="secondary"
                                variant="outlined"
                                onClick={() => cancelSubscription()}
                            >
                                Cancel subscription
                            </AppButton>}
                    </div>)}
                {loading === 1 && <span style={{ color: "grey" }}>Loadng subscription info...</span>}
                {(!active && loading !== 1) &&
                    <AppButton
                        color="secondary"
                        variant="outlined"
                        onClick={() => { accountAction(userAware, 'learn_more'); onClose(); }}
                    >
                        Upgrade
                    </AppButton>}
            </DialogContent>
            <DialogActions>
                <AppButton onClick={onClose}>
                    Close
                </AppButton>
            </DialogActions>
        </XSMobileDialog>
    </>;
};

export function accountAction(userAware: UserAware, name?: AccountActions) {
    if (name === 'upgrade_yearly') {
        stripeCheckout(userAware, 'year');
    } else if (name === 'upgrade_mnthly') {
        stripeCheckout(userAware, 'month');
    } else if (name === 'manage_subscription') {
        const manageSubscriptionPrompt = <ManageSubscriptionDialog
            userAware={userAware}
            onClose={() => hideAlert({ content: manageSubscriptionPrompt, ownContent: true, buttons: [] })}
        />;
        showAlertProps({ content: manageSubscriptionPrompt, ownContent: true, buttons: [] });
    } else if (name === 'learn_more') {
        pushUrl('/account/plans')
    }
}

export async function stripeCheckout(userAware: UserAware, subscription: SubscriptionInterval) {
    const hideProgress = showProgress('DuplicateEventDialog handleCreate');
    try {
        const { adminName, adminEmail } = await getUserInfo(userAware.workingUserId!);
        const checkoutRequest: CheckoutRequest = {
            token: '',
            userId: '',
            name: adminName,
            email: adminEmail,
            action: ACTION_CHECKOUT,
            interval: subscription,
            source: 'Checkout from Events settings',
            notificationLess: false,
            successUrl: window.location.origin + '/account/plans',
            cancelUrl: window.location.origin + '/account/plans'
        };
        await prepareRequest(checkoutRequest);
        const res = await axios.post(Urls.checkout, checkoutRequest);
        const responseData: CheckoutResponse = res.data;
        const checkoutUrl = responseData.checkoutUrl;
        dbgLog(`Checkout result: ${responseData.checkoutUrl}, time spent ${responseData.timeSpent}ms `);
        if (checkoutUrl) {
            window.location.replace(checkoutUrl);
        }
    } finally {
        hideProgress()
    }
}

export async function stripeCancel(userAware: UserAware) {
    const hideProgress = showProgress('DuplicateEventDialog handleCreate');
    try {
        const cancelRequest: CancelSubscriptionRequest = {
            token: '',
            source: 'Cancel subscription from Events settings',
            action: ACTION_CANCEL,
            userId: userAware.workingUserId!
        };
        await prepareRequest(cancelRequest);
        const res = await axios.post(Urls.cancelSubscription, cancelRequest);
        const responseData: CancelSubscriptionResponse = res.data;
        dbgLog(`Cancel result: time spent ${responseData.timeSpent}ms `);
    } finally {
        hideProgress()
    }
}
