import * as React from 'react';
import * as Backend from '../util/firebase';
import { hideAllProgresses } from '../redux/ReduxConfig';
import { logInfo } from 'src/util/utility';
import { useNavigate } from 'react-router-dom';
import { Urls } from 'src/util/config';
import CryptoJS from 'crypto-js';
import { MAX_GOLFERS, MAX_GOLFERS_PRO } from 'src/types/EventTypes';

const UTF8 = CryptoJS.enc.Utf8;
const HEX = CryptoJS.enc.Hex;

export type LoginStatus = 'None' | 'Logging' | 'Logged';

export interface UserAware {
    user?: Backend.User;
    loginStatus: LoginStatus;
    hasSup?: boolean;
    hasPro?: boolean;
    effectivePro?: boolean;
    eventId?: string;
    workingUserId?: string;
    effectiveUserId?: string;
    signingOut?: boolean;
    hasUpdated?: boolean;
    absMaxGolfers: number;
    setEffectiveUserId: (effectiveUserId?: string) => void;
    setSigningOut: (signingOut: boolean) => void;
    setHasUpdated: (hasUpdated: boolean) => void;
    setEventId: (eventId: string | undefined) => void;
    setEffectivePro: (val: boolean) => void;
}

export interface WithUserAware {
    userAware: UserAware;
}

export interface WithPossibleUserAware {
    userAware?: UserAware;
}

const UlogIn = () => Urls.logIn + '/' + UTF8.parse(window.location.origin + '/sso').toString(HEX);
const UsignUp = () => Urls.signUp + '/' + UTF8.parse(window.location.origin + '/sso').toString(HEX);
const UlogOut = () => Urls.logOut + '/' + UTF8.parse(window.location.origin + '/login').toString(HEX);

export function remoteLogout() {
    Backend.trackEvent('user_logout');
    window.location.assign(UlogOut());
}

export function remoteLogin() {
    hideAllProgresses();
    Backend.trackEvent('user_sign_in_start');
    window.location.assign(UlogIn());
}

export function remoteSignup() {
    hideAllProgresses();
    Backend.trackEvent('user_sign_up_start');
    window.location.assign(UsignUp());
}

export function RemoteLogin() {
    const navigate = useNavigate();
    Backend.trackEvent('user_sign_in_start');
    React.useEffect(() => {
        navigate(UlogIn());
    });
    return null;
}

export function RemoteSignup() {
    const navigate = useNavigate();
    Backend.trackEvent('user_sign_up_start');
    React.useEffect(() => {
        navigate(UsignUp());
    });
    return null;
}

export const UserAwareContext = React.createContext<UserAware>(null!);

export function UserAwareProvider({ children }: { children: React.ReactNode }) {
    const [loginStatus, setLoginStatus] = React.useState<LoginStatus>('Logging');
    const [hasSup, setSup] = React.useState(false);
    const [hasPro, setPro] = React.useState(false);
    const [effectiveUserId, setEffectiveUserId] = React.useState<string | undefined>();
    const [signingOut, setSigningOut] = React.useState(false);
    const [hasUpdated, setHasUpdated] = React.useState(false);
    const [eventId, setEventId] = React.useState<string | undefined>();
    const [user, setUser] = React.useState<Backend.User | undefined>();
    const [effectivePro, setEffectivePro] = React.useState(false);
    const [absMaxGolfers, setAbsMaxGolfers] = React.useState(MAX_GOLFERS);
    React.useEffect(() => {
        // const hideProgress = showProgress('onAuthStateChanged');
        let userUnsubscribe: VoidFunction | undefined = undefined;
        const authUnsubscribe = Backend.firebaseAuth.onAuthStateChanged(user => {
            logInfo(`u ${user?.uid}...`);
            if (!user) {
                if (userUnsubscribe) {
                    userUnsubscribe();
                    userUnsubscribe = undefined;
                }
                setSup(false);
                setPro(false);
                setAbsMaxGolfers(MAX_GOLFERS);
                setEffectivePro(false);
                setLoginStatus('None');
                setUser(undefined);
                // hideProgress();
                return;
            }
            userUnsubscribe = Backend.onSnapshot(Backend.doc(Backend.accessDb, user.uid), doc => {
                // hideProgress();
                const data = doc.data();
                logInfo(`u ${doc.id} ${data?.sup ?? false} ${data?.pro ?? false}`);
                setSup(data?.sup ?? false);
                setPro(data?.pro ?? false);
                setAbsMaxGolfers(data?.pro ? MAX_GOLFERS_PRO : MAX_GOLFERS);
                setEffectivePro(data?.pro ?? false);
                setLoginStatus('Logged');
                setUser(user);
            }/*, err => hideProgress(err.message)*/);
        }/*, err => hideProgress(err.message)*/);
        return () => {
            if (userUnsubscribe) {
                userUnsubscribe();
                userUnsubscribe = undefined;
            }
            if (authUnsubscribe) {
                authUnsubscribe();
            }
        }
    }, []);
    const value = {
        user, loginStatus,
        absMaxGolfers,
        hasSup, hasPro,
        effectiveUserId, setEffectiveUserId,
        signingOut, setSigningOut,
        hasUpdated, setHasUpdated,
        eventId, setEventId,
        effectivePro, setEffectivePro,
        workingUserId: effectiveUserId ?? user?.uid
    };
    return (
        <UserAwareContext.Provider value={value}>
            {children}
        </UserAwareContext.Provider>
    );
}

export function useUserAware() {
    return React.useContext(UserAwareContext);
}
