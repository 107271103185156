import * as React from 'react';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { EventData } from '../../../types/EventTypes';
import PrintTab from './PrintTab';
import ChangelogTab from './ChangelogTab';
import LinkTabs, { Tab } from '../common/LinkTabs';
import { styles } from '../../../styles';

type TabType = 'print' | 'changelog';

interface ToolsProperties {
    eventData: EventData;
    initial?: string;
    onSelectedTab: (subtab: string) => void;
}

function ToolsTab(props: ToolsProperties & WithStyles<typeof styles>) {
    const tabs: Array<Tab<TabType>> = [
        {
            id: 'print',
            label: 'PRINT',
            node: <PrintTab eventData={props.eventData} />
        },
        {
            id: 'changelog',
            label: 'CHANGELOG',
            node: <ChangelogTab event={props.eventData.event} />
        },
    ];
    return <LinkTabs parentUrl={`/events/${props.eventData.event.id}/tools`} initial={props.initial || 'print'} tabs={tabs} onSelectedTab={props.onSelectedTab} classes={props.classes} />;
}

export default withStyles(styles)(ToolsTab);
