import * as React from 'react';
import { List } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import * as Backend from '../../../../util/firebase';
import RichTextField from '../../../../common/form/richtext/RichTextField';
import LabeledField from '../../../../common/form/LabeledField';
import { Portal } from '../../../../types/EventTypes';
import { TEMPLATE_ABOUT, elogIds } from '../../../Event';
import ChooseImgDialog from './ChooseImgDialog';
import { styles } from '../../../../styles';
import { withProgress } from 'src/util/ProgressPromise';

type Props = { eventId: string; eventPubId: string; } & WithStyles<typeof styles>;

interface State {
    portal?: Portal;
    dialogOpened: boolean;
    imgTypeDialog: Backend.ImageType;
}

class PortalSettings extends React.Component<Props, State> {

    state = { dialogOpened: false } as State;

    componentDidMount() {
        Backend.trackEvent('view_portal_settings');
        this.loadPortalData();
    }

    private async loadPortalData() {
        const { eventId } = this.props;
        const portal = await withProgress(Backend.getEntity<Portal>(Backend.portalFields(eventId)));
        this.setState({ portal });
    }

    private handleSave = (key: keyof Portal) => (value: any) => {
        const { portal } = this.state;
        if (!portal) {
            return;
        }
        const { eventId, eventPubId } = this.props;
        (portal as any)[key] = value;
        Backend.updateOrAdd(Backend.portalInfoDb, portal, true)
            .then(() => elogIds(eventId, eventPubId, `Portal ${key} changed`, `No details`, `Id: ${eventId}`))
            .then(() => Backend.setUserDataPromise({ lastBadge: portal.badgeUrl, lastBanner: portal.bannerUrl }));
    }

    private handleSaveImg = (type: Backend.ImageType) => (url: string) => {
        this.handleSave(type === 'badge' ? 'badgeUrl' : 'bannerUrl',)(url);
        this.handleDialogClose();
    }

    private handleImgClick = (type: Backend.ImageType) => () => this.setState({ imgTypeDialog: type, dialogOpened: true });
    private handleDialogClose = () => this.setState({ dialogOpened: false });

    render() {
        const { classes } = this.props;
        const { portal } = this.state;
        const badgeUrl = portal ? portal.badgeUrl : undefined;
        const bannerUrl = portal ? portal.bannerUrl : undefined;
        const about = (portal && portal.about) ? portal.about : '';
        const template = TEMPLATE_ABOUT;
        const placeholder = 'Event description has not been added yet';
        const { imgTypeDialog, dialogOpened } = this.state;
        return <>
            <List className={classes.listRootGreyRound}>
                <LabeledField label="Badge" value={<img src={badgeUrl} className={classes.img} alt="" />} edit={this.handleImgClick('badge')} />
                <LabeledField label="Banner" value={<img src={bannerUrl} className={classes.img} alt="" />} edit={this.handleImgClick('banner')} />
                <RichTextField label="About" value={about} placeholder={placeholder} template={template} save={this.handleSave('about')} />
                {dialogOpened && <ChooseImgDialog
                    open={dialogOpened}
                    imageType={imgTypeDialog}
                    handleClose={this.handleDialogClose}
                    handleSelection={this.handleSaveImg(imgTypeDialog)} />}
            </List>
        </>;
    }
}

export default withStyles(styles)(PortalSettings);
