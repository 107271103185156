import * as React from 'react';
import { AppBar, List, ListItemButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CircularProgress } from '@mui/material';
import ButtonBar from '../common/components/ButtonBar';
import AppButton from '../common/components/AppButton';
import { useAppStyles } from '../styles';
import * as Utils from '../util/utility';
import { Event, Portal, Round, createEvent, getCurrentCourseName, getCurrentRound } from 'src/types/EventTypes';
import { Container, Flex, IconLinkElement, Item } from 'src/common/Misc';
import Logo from 'src/common/Logo';
import { pushUrl } from 'src/redux/ReduxConfig';
import ArcStepper from 'src/event/list/ArcStepper';
import { eventBadgeUrl, formatEventDateAndTime } from 'src/event/Event';
import { DateRangeIcon } from '@mui/x-date-pickers';
import { CopyIcon, LocationIcon } from 'src/common/Icons';
import { TileEventItem } from 'src/event/list/EventList';
import { GolfPadProgress } from 'src/common/GolfPadProgress';

const TEST_BADGE_URL = 'https://firebasestorage.googleapis.com/v0/b/project-8831674143385441783.appspot.com/o/portal-default%2Fbadge_4.jpg?alt=media';

type EventItemProps = {
    event: Event,
    rounds: Array<Round>;
    portal: Portal,
    portalLoaded: boolean,
    stepsTotal: number,
    stepsCompleted: number,
    participantsOk: boolean;
    scheduleOk: boolean;
    teesOk: boolean;
    today: boolean;
    handleItemClick: (event: Event, copyEvent: boolean) => void;
};

const Buttons = () => {
    return <ButtonBar>
        <AppButton color="inherit">Inherit</AppButton>
        <AppButton color="primary">Primary</AppButton>
        <AppButton color="secondary">Secondary</AppButton>
        <AppButton color="success">Success</AppButton>
        <AppButton color="warning">Warning</AppButton>
        <AppButton color="info">Info</AppButton>
        <AppButton color="error">Error</AppButton>
    </ButtonBar>;
};

function testEvent(name: string, courseName: string) {
    const event = createEvent('userId', 'userName', 'userEmail', name, Date.now());
    event.course = { id: '', name: courseName };
    return event;
}

export const TestTileEventItem = (props: EventItemProps) => {
    const { event, rounds, today, portal, portalLoaded, stepsTotal, stepsCompleted, handleItemClick } = props;
    const classes = useAppStyles();
    const [completeSetup, setCompleteSetup] = React.useState(0);
    const lessThanSm = useMediaQuery(useTheme().breakpoints.down('sm'));
    const currentRound = today && false ? getCurrentRound(event, rounds) : undefined;
    let buttonClicked = false;
    const aclick = () => {
        buttonClicked = true;
        setTimeout(() => buttonClicked = false);
    };
    const click = (e: React.MouseEvent, copyEvent: boolean) => {
        if (buttonClicked) {
            return;
        }
        e.preventDefault();
        buttonClicked = true;
        handleItemClick(event, copyEvent);
        setTimeout(() => buttonClicked = false);
    };
    const clickComplete = (e: React.MouseEvent) => {
        e.preventDefault();
        buttonClicked = true;
        setCompleteSetup(1);
        setTimeout(() => buttonClicked = false);
    };
    const clickCustomize = (e: React.MouseEvent) => {
        e.preventDefault();
        buttonClicked = true;
        setCompleteSetup(2);
        setTimeout(() => buttonClicked = false);
    };
    const clickStandings = (e: React.MouseEvent) => {
        e.preventDefault();
        buttonClicked = true;
        pushUrl(`/event/${event.publicId}/standings`);
    };
    /*
    const eventSetupClosed = (target?: string) => {
        setCompleteSetup(0);
        if (target) {
            pushUrl(target);
        }
    };
    */
    const arcStepper = <ArcStepper
        onClickSteps={clickComplete}
        badgeUrl={portalLoaded ? eventBadgeUrl(event, portal) : ''}
        stepsTotal={stepsTotal}
        stepsCompleted={stepsCompleted}
        size={'100%'}
    />;
    return <Item>
        <ListItemButton className={classes.tileBox} onClick={e => click(e, false)}>
            <Container spacing={2} alignItems="center">
                {!lessThanSm && <Item xs={3}>{arcStepper}</Item>}
                <span>
                    <Container spacing={0.5} alignItems="center">
                        <Item xs={12}>
                            <Typography noWrap className={classes.tileEventTitle}>{event.name}</Typography>
                        </Item>
                        <Item>
                            <Typography className={classes.link + ' ' + classes.tileEventText} noWrap>
                                <IconLinkElement href={`/event/${event.publicId}`} target="_blank" rel="noopener noreferrer" click={aclick}>Event site</IconLinkElement>
                            </Typography>
                        </Item>
                        <Item>
                            <Typography className={classes.link + ' ' + classes.tileEventText} noWrap>
                                <a href="" rel="noopener noreferrer" onClick={e => click(e, true)}>
                                    <Flex>
                                        <CopyIcon className={classes.textIcon} />
                                        Duplicate
                                    </Flex>
                                </a>
                            </Typography>
                        </Item>
                        {currentRound && <Item>
                            <Typography>Round {currentRound.roundOrder}</Typography>
                        </Item>}
                        <Item xs={12}>
                            <Typography noWrap>
                                <Flex>
                                    <DateRangeIcon className={classes.textIcon} />
                                    {formatEventDateAndTime(event, rounds, false, false)}
                                </Flex>
                            </Typography>
                        </Item>
                        <Item xs={12}>
                            <Flex>
                                <LocationIcon className={classes.textIcon} />
                                <span className={classes.labelOverflow}>{getCurrentCourseName(event, rounds)}</span>
                            </Flex>
                        </Item>
                        {portalLoaded && <Item xs={12}>
                            {stepsCompleted < stepsTotal &&
                                <AppButton color="secondary" onClick={clickComplete}>Complete event setup</AppButton>}
                            {stepsCompleted > stepsTotal &&
                                <AppButton color="secondary" onClick={clickStandings}>Standings</AppButton>}
                            {stepsCompleted === stepsTotal &&
                                <AppButton color="info" onClick={clickCustomize}>Customize event</AppButton>}
                        </Item>}
                    </Container>
                </span>
                {lessThanSm && <span style={{ width: '25%' }}>{arcStepper}</span>}
            </Container>
        </ListItemButton>
        {completeSetup > 0 && null}
    </Item>;
};

const TestList = () => {
    const classes = useAppStyles();
    const lessThanSm = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [randStr, setRandStr] = React.useState('');
    const portal: Portal = {
        id: '',
        badgeUrl: TEST_BADGE_URL
    };
    const events = [
        testEvent('Name 1', 'Bellevue'),
        testEvent('Name test name test name 2', 'Course'),
        testEvent('Name test 3', 'Bellevue Crossroads Par 3 Golf Course')
    ];
    const arcStepper = <span style={{ width: '25%', padding: 8, backgroundColor: '#DAEAFF' }} className={classes.labelOverflow}>
        <Flex placeCenter placeMiddle>
            <ArcStepper
                badgeUrl={TEST_BADGE_URL}
                stepsTotal={0}
                stepsCompleted={0}
                size={'100%'}
            />
        </Flex>
    </span>;
    return <>
        <AppBar position="static">
            <Toolbar variant="dense" sx={{ height: 56 }}>
                <Logo onclk={() => pushUrl('/events')} extclk={() => { }} />
            </Toolbar>
        </AppBar>
        <CircularProgress />
        <GolfPadProgress />
        <div className={classes.listRootGreyRound}>
            <ListItemButton className={classes.tileBox}>
                <div style={{ width: '100%' }}>
                    <Flex>
                        <span style={{ width: '75%', backgroundColor: '#DAFFEA' }} className={classes.labelOverflow}>
                            LEFT LEFT LEFT LEFT LEFT LEFT LEFT LEFT LEFT LEFT LEFT LEFT LEFT
                        </span>
                        <span style={{ width: '25%', backgroundColor: '#DAEAFF' }} className={classes.labelOverflow}>
                            RIGHT
                        </span>
                    </Flex>
                    <Flex>
                        <span style={{ width: '75%', backgroundColor: '#DAFFEA' }} className={classes.labelOverflow}>
                            <Item xs={12}>
                                <Typography className={classes.tileEventTitle}>Event name</Typography>
                            </Item>
                            <Item>
                                <DateRangeIcon className={classes.textIcon} />
                                NNN-NN-N
                            </Item>
                            <Item>
                                <LocationIcon className={classes.textIcon} />
                                <span>{getCurrentCourseName(testEvent('Name string', 'Course Name string'), [])}</span>
                            </Item>
                        </span>
                        {arcStepper}
                    </Flex>
                    <Flex>
                        {!lessThanSm && arcStepper}
                        <span style={{ width: '75%', backgroundColor: '#DAFFEA' }} className={classes.labelOverflow}>
                            <Item xs={12}>
                                <Typography className={classes.tileEventTitle}>Event name name name name</Typography>
                            </Item>
                            <Item>
                                <DateRangeIcon className={classes.textIcon} />
                                NNN-NN-N
                            </Item>
                            <Item>
                                <LocationIcon className={classes.textIcon} />
                                <span>{getCurrentCourseName(testEvent('Name string', 'Course Name string Name string Name string'), [])}</span>
                            </Item>
                        </span>
                        {lessThanSm && arcStepper}
                    </Flex>
                </div>
            </ListItemButton>
        </div>
        <Container className={classes.listRootGreyRound}>
            {events.map((item, idx) =>
                <TileEventItem
                    key={idx}
                    event={item}
                    handleItemClick={() => { }}
                    rounds={[]}
                    portal={portal}
                    portalLoaded={true}
                    stepsTotal={0}
                    stepsCompleted={0}
                    participantsOk={false}
                    scheduleOk={false}
                    teesOk={false}
                    today={false}
                />)}
        </Container>
        LIST 1
        <List disablePadding>
            <Buttons />
        </List>
        <div className={classes.root}>
            LIST 2
            <List disablePadding className={classes.listRootGreyRound}>
                <Buttons />
            </List>
        </div>
        <div className={classes.root}>
            <List disablePadding className={classes.listRootGreyRound}>
                <AppButton color="secondary" onClick={() => setRandStr(Utils.randomString(5))}>Test Random String</AppButton>
                &nbsp;&nbsp;&nbsp;{randStr}
            </List>
        </div>
        <div className={classes.root}>
            <Typography>Typography</Typography>
            <Typography color="aqua">Typography</Typography>
        </div>
    </>;
};

export default TestList;
