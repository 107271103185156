import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { WithStyles, StyleRules } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import ContactAvatar from './ContactAvatar';
import { avatarsRef } from '../../util/firebase';
import ImageCropDialog from '../../common/components/ImageCropDialog';
import { IconButton } from "@mui/material";

const deleteIcon = '/img/deleteIcon.svg';
const cameraIcon = '/img/cameraIcon.svg';
const editorSize = 312;

const WStyles = withStyles((theme: Theme) => {
    const spacing = theme.spacing(3);
    return {
        avatar: {
            marginRight: spacing,
            height: 80,
            width: 80,
        },
        deleteIcon: {
            position: 'absolute',
            left: 60,
            bottom: 58
        },
        cameraIcon: {
            position: 'absolute',
            left: 60,
            top: 58
        }
    } as StyleRules;
});

interface Props {
    url?: string;
    handleAvatarChange: (url?: string) => void;
    isXs: boolean;
}

interface State {
    file?: File;
}

class EditableAvatarInner extends React.Component<Props & WithStyles<StyleRules>, State> {
    state: State = {};

    private handleAvatarRemove = () => this.props.handleAvatarChange();
    private handleDialogClose = () => this.setState({ file: undefined });

    private handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        this.setState({ file });
        e.currentTarget.value = '';
    }

    render() {
        const { classes, url, isXs, handleAvatarChange } = this.props;
        return <>
            <div style={{ position: 'relative' }}>
                <ContactAvatar url={url} classes={{ avatar: classes.avatar }} />
                {url &&
                    <IconButton className={classes.deleteIcon} onClick={this.handleAvatarRemove} size="large">
                        <img src={deleteIcon} color="secondary" alt="" />
                    </IconButton>}
                <IconButton className={classes.cameraIcon} component="label" size="large">
                    <img src={cameraIcon} color="secondary" alt="" />
                    <input onChange={this.handleFile} style={{ display: 'none' }} type="file" />
                </IconButton>
            </div>
            <ImageCropDialog file={this.state.file}
                setImage={handleAvatarChange}
                close={this.handleDialogClose}
                height={editorSize} width={editorSize}
                isXs={isXs} storageRef={avatarsRef} maxWidth="xs" rounded={true} />
        </>;
    }
}

export default WStyles(EditableAvatarInner);
