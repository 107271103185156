import * as React from 'react';
import { Box } from "@mui/material";
import { WithStyles } from '@mui/styles';
import { Route, Routes, Navigate, useParams, useNavigate, NavigateFunction } from 'react-router-dom';
import { useAppStyles, styles } from "../../../styles";
import { AppColors } from "../../../main/Theme";
import { Flex } from 'src/common/Misc';

interface LinkTabProps<T> {
    label: React.ReactNode;
    subLabel?: React.ReactNode;
    active: boolean;
    value: T;
    pub?: boolean;
    getIcon?: (active: boolean) => React.ReactNode;
    onSelected: (tab: T) => void;
    index: number;
}

const LinkTab = function <T>(props: LinkTabProps<T>) {
    const { active, value, label, subLabel, pub, index, getIcon, onSelected } = props;
    const classes = useAppStyles();
    const cls = pub ?
        (classes.tabPub + ' ' + (active ? classes.activePub : classes.passivePub)) :
        (classes.tab + ' ' + (active ? classes.active : classes.passive));
    const icon = getIcon?.(active);
    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (pub) {
            const element = document.getElementById(`item-${index}`);
            element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
        onSelected(value);
    };
    return (
        <a href="/" className={cls} onClick={onClick} id={`item-${index}`} style={{
            cursor: 'pointer', whiteSpace: 'nowrap',
            borderBottom: pub ? active ? `2px solid ${AppColors.bluish}` : `1px solid ${AppColors.webGrey200}` : 'unset'
        }}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                {icon}
                {icon && <Box width={8} />}
                <Box display="flex" flexDirection="column" alignItems="center">
                    {label}
                    {subLabel}
                </Box>
            </Box>
        </a>
    );
};

export interface Tab<T extends string> {
    id: T;
    label: React.ReactNode;
    subLabel?: React.ReactNode;
    node?: React.ReactNode;
    getIcon?: (active: boolean) => React.ReactNode;
}

type TabChanger<T> = (tab: T) => void;
type TabCreator<T extends string> = (changeTab: TabChanger<T>) => Tab<T>;

interface SettingsProperties<T extends string> {
    tabs: Array<Tab<T> | TabCreator<T>>;
    initial: T;
    maxWidth?: number;
    pub?: boolean;
    sticky?: boolean;
    onSelectedTab: (tab: T) => void;
}

type PropsInner<T extends string> = SettingsProperties<T> & { parentUrl: string } & { tab: T; navigate: NavigateFunction; };

class TabsInnerImpl<T extends string> extends React.Component<PropsInner<T> & WithStyles<typeof styles>> {
    private tabs: Array<Tab<T>> = [];

    constructor(props: PropsInner<T> & WithStyles<typeof styles>) {
        super(props);
        this.updateTabs(this.props.tabs);
    }

    shouldComponentUpdate(nextProps: PropsInner<T>) {
        if (nextProps.tabs !== this.props.tabs) {
            this.updateTabs(nextProps.tabs);
            return true;
        }
        return false;
    }

    private updateTabs(tabs: Array<Tab<T> | TabCreator<T>>) {
        this.tabs = tabs.map(t => {
            if (typeof t === 'function') {
                return t(this.changeTab);
            } else {
                return t;
            }
        });
    }

    private changeTab = (value: T) => {
        const { navigate, parentUrl, onSelectedTab } = this.props;
        onSelectedTab(value);
        navigate(`${parentUrl}/${value}`)
    };

    render() {
        const { classes, maxWidth, pub, sticky, tab } = this.props;
        const selectedTab = this.tabs.filter((t) => t.id === tab)[0];
        const className = (pub ? classes.tabRootPub : classes.tabRoot) + ' ' + (sticky ? classes.stickyTop : '');
        return (
            <div className={className} style={{ maxWidth }}>
                <Flex>
                    {this.tabs.map((t, i) =>
                        <LinkTab index={i} key={t.id} label={t.label} subLabel={t.subLabel} pub={pub} active={t.id === tab} value={t.id} getIcon={t.getIcon} onSelected={this.changeTab} />)}
                </Flex>
                {selectedTab && selectedTab.node}
            </div>
        );
    }
}

function TabsInner<T extends string>(props: Omit<PropsInner<T>, 'tab' | 'navigate'> & WithStyles<typeof styles>) {
    const { tab } = useParams<'tab'>();
    if (!tab) {
        return null;
    }
    const navigate = useNavigate();
    return <TabsInnerImpl {...props} navigate={navigate} tab={tab as T} />;
}

type Props<T extends string> = SettingsProperties<T> & WithStyles<typeof styles> & { parentUrl: string };

function LinkTabs<T extends string>(props: Props<T>) {
    return <>
        <Routes>
            <Route path={`:tab`}
                element={<TabsInner {...props} />} />
            <Route index element={<Navigate to={props.initial} replace />} />
        </Routes>
    </>;
}

export default LinkTabs;
