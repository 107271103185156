import * as React from 'react';
import {
    DialogActions, DialogContent, FormControl, FormControlLabel, Typography, Radio, RadioGroup,
    Paper, Grid, StyledEngineProvider, InputAdornment, IconButton, TextField,
    Checkbox, ButtonGroup, Stepper, Step, StepLabel, StepIconProps, useMediaQuery,
    useTheme, List, ListItemButton
} from '@mui/material';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
    ScoringMode, ScoringModes, ScoringFormatIndividual, ScoringFormat, ScoringFormatTeams,
    ScoringTeamSize, Event, Competition, CompetitionLegacy, EventGender, ResultStatus, Tee, UserInfo, Round, getCourseName, Course, HolesType,
    ScoringFormatSkins, isNetMode, ScoringType, EventPaymentSettings, getSimpleTeeName,
    EVENT_GENDER_LABELS, HOLES_NAMES, TEAM_SCORINGS_AND_SKINS, INDIVIDUAL_SCORINGS, INDIVIDUAL_SCORINGS_AND_SKINS, SCORING_TEAM_SIZES,
    DEFAULT_EVENT_PAYMENT_SETTINGS_ENABLED, DEFAULT_REGISTRATION_EMAIL_NOTIFY, MAX_ROUNDS, EventBase, REGISTRATION_DEADLINE_DAYS,
    getRegistrationDate, createEvent, setEventDate, createRound, correctRoundDates, updateRoundDates, correctRoundTeeTimes
} from '../../types/EventTypes';
import {
    createNewEvent, getPortalInfo, getUserInfo, createCompetition, fillProportionalHandicaps,
    fillSameHandicaps, fillAlternateHandicaps, fillChapmanHandicaps, formatDateUniversal, tryUpdateUserPayPalInfo,
    getRoundDates
} from '../Event';
import * as AppTheme from '../../main/Theme';
import { MON_DATE_FORMAT, EVENT_DATETIME_FORMAT_DLG, Urls } from '../../util/config';
import * as Backend from '../../util/firebase';
import * as Scoring from '../../scoring/scoring';
import { processEnterKey } from '../../util/react_utils';
import * as Utils from '../../util/utility';
import { Func } from '../../util/utility';
import MaterialDate from '../../common/MaterialDate';
import { SMMobileDialog } from '../../common/dialog/MobileDialog';
import DialogAppBar from '../../common/dialog/DialogAppBar';
import AppButton from '../../common/components/AppButton';
import CopyTextField from '../../common/components/CopyTextField';
import { Flex, Spacing, EditIcon, ProgressRadio } from '../../common/Misc';
import { LinkIcon, SettingsIcon, BkCloseButton, PlusIcon, FlagIcon } from '../../common/Icons';
import { showProgress, NoticeElement } from '../../redux/ReduxConfig';
import { HandicapPercentSelector } from '../tabs/scores/CompetitionSettingsDialog';
import StablefordPointSettingsDialog from '../tabs/scores/StablefordPointSettingsDialog';
import { AppColors } from "../../main/Theme";
import { InfoIconTooltip } from "../../common/components/InfoToolTip";
import { PayPalPaymentSettings } from "../../payments/paypal/PayPalPaymentSettings";
import { CourseSetupPage } from './CourseSetupPage';
import { RoundEditDialog } from './RoundEditDialog';
import { FirebaseUserDataComponent } from "../../common/WithData";
import { ParticipantsNumberField } from "../../common/components/ParticipantsNumberField";
import { PracticeRoundPage } from './PracticeRoundPage';
import { } from 'src/scoring/handicap';
import { NEW_EVENT_ELEM_WIDTH } from 'src/styles';
import { WithUserAware, useUserAware } from 'src/auth/Auth';
import ProBadge from 'src/common/ProBadge';
import ProPlanDialog from './ProPlanDialog';
import { AccountActions, accountAction } from 'src/auth/StripeRedirect';

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme { }
}

const scoringFormatDescriptions = new Map<ScoringFormat, Array<string>>([
    [
        ScoringFormatIndividual.strokeplay, [
            'This is the most common tournament format. Leaderboard is ranked by each golfer’s gross or net score.']
    ], [
        ScoringFormatIndividual.stableford, [
            'This format rewards aggressive play style with extra incentives to go for birdies and eagles.',
            'Leaderboard is ranked by Stableford points derived from each golfer’s gross or net score.']
    ], [
        ScoringFormatSkins.skins_individual, [
            'In this format each hole is worth one skin. The golfer with the lowest score on a hole wins the skin, no skin is awarded in case of a tie.',
            'The player with the highest number of skins at the end of the competition wins.']
    ], [
        ScoringFormatTeams.best_ball, [
            'This team format offers the great balance of a competitive and low pressure tournament experience.',
            'Leaderboard is ranked by team score on each hole, which is the best individual score of the team from each hole.']
    ], [
        ScoringFormatTeams.alternate_shot, [
            'The competition usually consists of 2-person teams.',
            'The format suggests teammates to alternate shot on each hole, throughout the competition. Regardless of which golfer finishes the previous hole, teammates must alternate tee shots throughout the round.']
    ], [
        ScoringFormatTeams.scramble, [
            'The format suggests relaxed rules and lack of pressure to achieve individual scores.',
            'After team members tee off, the team selects the best result and everyone plays their next shot from this spot. This process is continued throughout the rest of the shots (including putting), until the ball is holed.']
    ], [
        ScoringFormatTeams.chapman, [
            'The competition usually consists of 2-person teams.',
            'Teammates both tee off and then switch balls and play the other persons tee shot. Team then selects the best second shot result and alternate shots in from there, until the ball is holed.']
    ], [
        ScoringFormatSkins.skins_team, [
            'In this format each hole is worth one skin. The team with the lowest score on a hole wins the skin, no skin is awarded in case of a tie.',
            'The team with the highest number of skins at the end of the competition wins.']
    ]
]);

const scoringModeDescriptions = new Map<ScoringMode, Array<string>>([
    [
        'gross',
        ['Gross scoring is a total of all strokes plus penalty strokes. It\'s a good, simple scoring method for golfers just starting out or those playing by themselves.']
    ], [
        'net',
        ['Net format scores incorporate a player\'s course handicap into this process. The net score is determined by subtracting the player\'s handicap from the gross score (number of strokes actually taken).']
    ], [
        'gross+net',
        ['Both gross and net scores are being calculated separately. This option provides more information for players to see.']
    ]
]);

const scoringTypeDescriptions = new Map<ScoringType, Array<string>>([
    [
        'strokeplay',
        ['This scoring type represents a common way of points calculation. The golfer with the best overall score at the end of the round, or number of rounds is deemed the winner.']
    ], [
        'stableford',
        ['The goal of this scoring type is to amass more points than your opponents throughout the round or competition. This option also allows points customization based on your preferences.']
    ]
]);

const ScoringFormatRadio = ({ teamMode, scoringFormat, selectedScoringFormat, width, onClick }: { teamMode: boolean, scoringFormat: ScoringFormat, selectedScoringFormat: ScoringFormat, width?: number, onClick?: Func<void> }) => {
    return (
        <span key={scoringFormat} style={{ width }}>
            <FormControlLabel key={scoringFormat}
                value={scoringFormat}
                style={{ marginLeft: 0 }}
                control={<Radio color="secondary" />}
                label={Utils.makeFriendlyString(scoringFormat, true)} />
            {Boolean(teamMode && scoringFormat === ScoringFormatIndividual.stableford && onClick) &&
                <IconButton
                    color="default"
                    disabled={selectedScoringFormat !== ScoringFormatIndividual.stableford}
                    onClick={onClick}
                    size="large"><SettingsIcon /></IconButton>}
            {Boolean(!teamMode && scoringFormat === ScoringFormatIndividual.stableford && onClick) &&
                <AppButton color="info" sx={{ '&.Mui-disabled': { backgroundColor: 'inherit', borderColor: '#949494' } }}
                    disabled={selectedScoringFormat !== ScoringFormatIndividual.stableford} onClick={onClick}>
                    <SettingsIcon /> Point settings
                </AppButton>}
        </span>
    );
}

const stepperTheme = createTheme({
    components: {
        MuiStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: AppColors.primary,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 0,
                    paddingBottom: 20,
                    '@media (max-width: 400px)': {
                        paddingLeft: 8,
                        paddingRight: 8,
                    },
                    '@media (max-width: 360px)': {
                        paddingLeft: 4,
                        paddingRight: 4,
                    },
                }
            }
        },
        MuiStep: {
            styleOverrides: {
                root: {
                    '&.MuiStep-horizontal': {
                        padding: 0,
                    },
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    '@media (max-width: 400px)': {
                        fontSize: '0.7rem',
                    },
                    '@media (max-width: 360px)': {
                        fontSize: '0.6rem',
                    },
                    '@media (max-width: 300px)': {
                        fontSize: '0.5rem',
                    },
                    color: AppColors.webBlue100,
                    '&.Mui-completed': {
                        color: AppColors.webBlue100
                    },
                    '&.Mui-active': {
                        color: AppColors.white
                    },
                }
            }
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    borderColor: AppColors.webBlue100,
                    marginLeft: 8,
                    marginRight: 8,
                    '@media (max-width: 360px)': {
                        marginLeft: 2,
                        marginRight: 2,
                    },
                }
            }
        }
    }
});

const dialogStyles = makeStyles({
    icon: {
        width: 22,
        height: 22,
        fontSize: '0.75rem',
        borderRadius: '50%',
        border: '1px solid',
        color: AppColors.webBlue100,
        backgroundColor: AppColors.primary,
    },
    linkWithPointer: {
        display: 'inline-block',
        color: '#025987',
        cursor: "pointer",
        textDecoration: "underline"
    }
});

const HandicapInfoIcon = () => {
    const classes = dialogStyles();
    return (<NoticeElement>
        Percentage shown is the most commonly used based on your scoring selection. Organizers can adjust allowance as they see fit.&nbsp;
        <a href={'https://support.golfpadgps.com/a/solutions/articles/6000266415'} target="_blank" rel="noopener noreferrer" className={classes.linkWithPointer}>Learn more</a>
    </NoticeElement>);
}

const StepIcon = (props: StepIconProps) => {
    const { icon, active, completed } = props;
    const color = active || completed ? AppColors.primary : AppColors.webBlue100;
    const classes = dialogStyles();
    return (
        <div className={classes.icon}>
            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ pointerEvents: 'none' }}>
                {completed && <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z" fill={AppColors.webBlue100} stroke={AppTheme.AppColors.webBlue100} />}
                {active && <circle cx="12" cy="12" r="12" fill={AppColors.white} stroke={AppColors.white} />}
                {!completed && <text x="12" y="16" textAnchor="middle" fill={color}>{icon}</text>}
            </svg>
        </div>
    );
}

const ProgressBar = ({ steps, selectedStep }: { steps: Array<string>, selectedStep: number }) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={stepperTheme}>
                <Stepper activeStep={selectedStep}>
                    {steps.map((step, idx) => {
                        const theme = useTheme();
                        const isXs = useMediaQuery(theme.breakpoints.down('sm'));
                        return (<Step key={step}>
                            <StepLabel StepIconComponent={StepIcon}>{isXs ? (idx <= selectedStep ? step : "") : step}</StepLabel>
                        </Step>);
                    })}
                </Stepper>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

type RegistrationRadioGroupProps = {
    typo1: React.ReactNode;
    typo2: React.ReactNode;
    onOptionChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
    registeringByAdmin: boolean;
    disabled: boolean;
    registrationLink: string;
};

const RegistrationRadioGroup = ({ typo1, typo2, onOptionChanged, registeringByAdmin, disabled, registrationLink }: RegistrationRadioGroupProps) => {
    const lessThanMd = useMediaQuery(useTheme().breakpoints.down('md'));
    return (
        <RadioGroup value={String(registeringByAdmin)} sx={{ flexDirection: 'row' }} onChange={onOptionChanged}>
            {lessThanMd ? (<Grid container>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <ProgressRadio value="false" label={typo1} />
                        <CopyTextField
                            readOnly btnOnly
                            color="secondary"
                            style={{ marginLeft: 36 }}
                            fieldValue={registrationLink}
                            btnLabel={<>Copy link<LinkIcon /></>}
                            disabled={disabled} />
                        <ProgressRadio value="true" label={typo2} />
                    </div>
                </Grid>
            </Grid>) : (<Grid container>
                <Grid item xs={6}>
                    <ProgressRadio value="false" label={typo1} />
                    <CopyTextField
                        readOnly btnOnly
                        color="secondary"
                        style={{ marginLeft: 36 }}
                        fieldValue={registrationLink}
                        btnLabel={<>Copy link<LinkIcon /></>}
                        disabled={disabled} />
                </Grid>
                <Grid item xs={6}>
                    <ProgressRadio value="true" label={typo2} />
                </Grid>
            </Grid>)}
        </RadioGroup>
    );
};

type RowRoundItemProps = {
    event: Event;
    round: Round;
    clickHandler: (round: Round) => void;
}

const RowRoundItem = ({ round, clickHandler }: RowRoundItemProps) => {
    const courseName = getCourseName(round.course) || 'Set course';
    const lessThanSm = useMediaQuery(useTheme().breakpoints.down('sm'));
    const roundElement = <>
        <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>Round {round.roundOrder}</Typography>
        <Typography sx={{ fontWeight: 500, fontSize: '12px' }}>Date: {formatDateUniversal(round.date, MON_DATE_FORMAT)}</Typography>
    </>;
    const teesMen = getSimpleTeeName(round.teeMen);
    const teeWomen = getSimpleTeeName(round.teeWomen);
    let teesName = teesMen && teeWomen ? teesMen + ' and ' + teeWomen : teesMen || teeWomen;
    if (teesName) {
        teesName = `, ${teesName} tees`;
    }
    const courseElement = <>
        <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
            <Flex>
                <span style={{ color: !round.course ? AppColors.webGrey300 : undefined }}>{courseName}</span>
                <FlagIcon sx={{ width: 16 }} />
            </Flex>
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: '12px' }}>{HOLES_NAMES[round.holesType ?? 0]} {teesName}</Typography>
    </>;
    return <>
        <ListItemButton sx={{ backgroundColor: AppColors.webGrey100, borderRadius: '4px' }} onClick={() => clickHandler(round)}>
            {lessThanSm ?
                <Grid container>
                    <Grid item xs={11}>
                        {roundElement}
                        <Spacing />
                        {courseElement}
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: 'right' }}><EditIcon /></Grid>
                </Grid> :
                <Grid container>
                    <Grid item xs={4}>{roundElement}</Grid>
                    <Grid item xs={7}>{courseElement}</Grid>
                    <Grid item xs={1} sx={{ textAlign: 'right' }}><EditIcon /></Grid>
                </Grid>}
        </ListItemButton >
        <Spacing height={8} />
    </>;
};

type EventOrgType = 'single-day' | 'multi-day';

type PageType =
    'Event details' |
    'Course setup' |
    'Course' |
    'Registration' |
    'Scoring' |
    'Rounds';

type Props = {
    noDuplicate: boolean;
    handleClose: (addedId?: string) => void;
    handleDuplicateEvent: () => void;
} & WithUserAware;

interface State {
    event: Event;
    rounds: Array<Round>;
    eventOrgType?: EventOrgType;
    pages: Array<Func<React.ReactNode>>;
    steps: Array<PageType>;
    defaultEventName: string;
    lastBadge?: string;
    lastBanner?: string;
    currentStep: number;
    teamSizeBackup: ScoringTeamSize;
    individualScoring: ScoringFormatIndividual | ScoringFormatSkins.skins_individual;
    teamScoringFormat: ScoringFormatTeams | ScoringFormatSkins.skins_team;
    scoringMode: ScoringMode;
    showPointSettings?: boolean;
    stablefordPoints?: Array<number>;
    handicapAllowances: Array<number>;
    editingTee?: Tee;
    coursesActivated?: boolean;
    teesLoadStatus?: ResultStatus;
    descriptionType: 'scoringFormat' | 'scoringMode' | 'scoringType';
    paymentSettings: EventPaymentSettings;
    editedRound?: Round;
    practiceRound: boolean;
    notCombinedTees?: Array<Tee>;
    userInfo?: UserInfo;
}

class NewEventDialogDetailed extends React.Component<Props, State> {
    state: State = {
        pages: [],
        steps: [],
        rounds: [],
        event: createEvent('', '', '', '', 0),
        defaultEventName: '',
        currentStep: 0,
        teamSizeBackup: 2,
        individualScoring: ScoringFormatIndividual.strokeplay,
        teamScoringFormat: ScoringFormatTeams.best_ball,
        scoringMode: 'gross',
        handicapAllowances: [100, 100, 100, 100],
        descriptionType: 'scoringFormat',
        paymentSettings: {
            enabled: DEFAULT_EVENT_PAYMENT_SETTINGS_ENABLED,
            platform: 'PayPal',
            feeCost: 0,
            payeeEmailAddress: '',
            currencyCode: ''
        },
        practiceRound: false
    };

    private readonly inputRef: React.RefObject<HTMLDivElement>;
    private readonly courseSetupPageRef: React.RefObject<CourseSetupPage>;

    constructor(props: Props) {
        super(props);
        this.inputRef = React.createRef();
        this.courseSetupPageRef = React.createRef();
    }

    componentDidMount() {
        this.loadInfo();
    }

    private loadInfo = async () => {
        const { userAware } = this.props;
        const hideProgress = showProgress('NewEventDialogDetailed loadInfo');
        const { adminName, adminEmail, lastBadge, lastBanner } = await getUserInfo(userAware.workingUserId!);
        const defaultEventName = Utils.upperFirstLetter(adminName.split(' ')[0]) + `'s tournament`;
        const event = createEvent(userAware.workingUserId!, adminName, adminEmail, defaultEventName, 0);
        hideProgress();
        this.setState({ event, defaultEventName, lastBadge, lastBanner });
    }

    private handleClose = () => {
        Backend.trackEvent('new_event_canceled');
        this.props.handleClose();
    }

    private handleDuplicateEvent = () => {
        Backend.trackEvent('duplicate_event_started');
        this.props.handleDuplicateEvent();
    }

    private handleCloseNoBackdrop = (_e: string, reason: string) => {
        if (this.courseSetupPageRef.current?.closePopups()) {
            return;
        }
        if ("backdropClick" === reason) {
            return;
        }
        this.handleClose();
    }

    private makeCompetition = (round?: Round) => {
        const { event, individualScoring, teamScoringFormat, scoringMode, stablefordPoints, handicapAllowances } = this.state;
        const competition: CompetitionLegacy = createCompetition(event.teamSize, [], event.teamSize > 1 ? teamScoringFormat : individualScoring, scoringMode, handicapAllowances);
        competition.scoring.stablefordMode = individualScoring === ScoringFormatIndividual.stableford;
        if (round) {
            competition.roundOrder = round.roundOrder;
        }
        if (stablefordPoints) {
            competition.scoring.mstablefordPoints = stablefordPoints;
            if (competition.scoring.format === ScoringFormatIndividual.stableford && stablefordPoints.length > 0 &&
                !Utils.equalArrays(stablefordPoints, Scoring.defaultStablefordPoints)) {
                competition.scoring.format = ScoringFormatIndividual.modified_stableford;
            }
        }
        competition!.tees = [round?.teeMen ?? (event.teeMen ?? null), round?.teeWomen ?? (event.teeWomen ?? null)];
        return competition;
    }

    private trySaveUserPaymentData = async () => {
        const { userInfo, event } = this.state;
        const paymentSettings = event.paymentSettings;
        await tryUpdateUserPayPalInfo(userInfo, paymentSettings, undefined);
    };

    private handleSave = async () => {
        const { event, rounds, defaultEventName, lastBadge, lastBanner, practiceRound, paymentSettings } = this.state;
        const hideProgress = showProgress('NewEventDialogDetailed handleSave');
        try {
            if (practiceRound) {
                event.practiceRoundInfo = event.practiceRoundInfo ?? '';
            } else {
                delete event.practiceRoundInfo;
            }
            event.name = event.name.trim() || defaultEventName;
            if ((paymentSettings.payeeEmailAddress.length > 0 && paymentSettings.feeCost > 0) ||
                (!paymentSettings.enabled && (paymentSettings.payeeEmailAddress.length > 0 || paymentSettings.feeCost > 0))) {
                event.paymentSettings = paymentSettings;
            }
            const competitions = new Array<Competition>();
            if (event.type === 'multiday') {
                rounds.forEach(round => {
                    round.teamSize = event.teamSize;
                    round.appCompetition = this.makeCompetition(round);
                    competitions.push(round.appCompetition);
                });
                correctRoundTeeTimes(rounds);
                const { minDate, maxDate } = getRoundDates(rounds);
                event.date = minDate;
                event.finishDate = maxDate;
            } else {
                event.appCompetition = this.makeCompetition();
                competitions.push(event.appCompetition);
            }
            const portal = await getPortalInfo(lastBadge, lastBanner)
            const id = await createNewEvent(event, portal, rounds, competitions);
            await this.trySaveUserPaymentData();
            this.props.handleClose(id);
        } catch (err) {
            Utils.dbgLog(`handleSave error ${err}`);
        } finally {
            hideProgress();
        }
    }

    private handleNext = async () => {
        const { event, steps } = this.state;
        let { currentStep } = this.state;
        if (currentStep < steps.length - 1) {
            currentStep++;
            this.setState({ currentStep });
            if (steps[currentStep] === 'Registration' && !event.publicId) {
                const hideProgress = showProgress('NewEventDialogDetailed handleNext');
                event.publicId = await Backend.getFreePubId();
                this.setState({ event });
                hideProgress();
            } else if (steps[currentStep] === 'Course setup' || steps[currentStep] === 'Course') {
                this.setState({ coursesActivated: true });
            } else if (steps[currentStep] === 'Rounds') {
                const { rounds } = this.state;
                rounds.forEach(round => {
                    if (!round.course) {
                        round.course = event.course;
                        round.holesType = event.holesType;
                        round.teeMen = event.teeMen;
                        round.teeWomen = event.teeWomen;
                    }
                    if (!round.name) {
                        round.name = `${event.name}, Round\xa0${round.roundOrder}`;
                    }
                });
                this.setState({ rounds });
            }
        } else {
            this.handleSave();
        }
    }

    private handleBack = () => {
        let { currentStep } = this.state;
        if (currentStep > 0) {
            currentStep--;
            this.setState({ currentStep });
        }
    }

    private setEventProp = (prop: keyof Event, val: any) => {
        const { event } = this.state;
        (event as any)[prop] = val;
        this.setState({ event });
    }

    private setEventAndRoundsProp = (prop: keyof EventBase, val: any) => {
        const { event, rounds } = this.state;
        (event as any)[prop] = val;
        rounds.forEach(round => (round as any)[prop] = prop === 'name' ? `${val}, Round\xa0${round.roundOrder}` : val);
        this.setState({ event });
    }

    private setEventName = (val: string) => this.setEventAndRoundsProp('name', Utils.toSafeString(val));

    private setEventDate = (date: Date) => {
        const { event, rounds } = this.state;
        setEventDate(event, date);
        if (rounds.length > 0) {
            correctRoundDates(event.date, 0, rounds);
        }
        const eventOrRoundDate = event.type === 'multiday' && rounds.length > 0 ? rounds[0].date : event.date;
        if (event.registrationDeadline && event.registrationDeadline > eventOrRoundDate) {
            event.registrationDeadline = eventOrRoundDate;
        }
        this.setState({ event, rounds });
    };

    private courseUpdated = (course?: Course, holesType?: HolesType, teeMen?: Tee, teeWomen?: Tee) => {
        const { event } = this.state;
        event.course = course;
        event.holesType = holesType;
        event.teeMen = teeMen;
        event.teeWomen = teeWomen;
        this.setState({ event });
    };

    private courseIsMissing = () => this.courseSetupPageRef.current?.courseIsMissing();

    private coursesAreMissing = () => {
        const { rounds } = this.state;
        return rounds.find(round => !round.course) !== undefined;
    }

    private eventDetailsPage = () => {
        const { event, defaultEventName } = this.state;
        const endAdornment = event.name ?
            <InputAdornment position="end">
                <BkCloseButton onClick={() => this.setEventName('')} />
            </InputAdornment> : null;
        return <>
            <FormControl variant="standard" margin="dense" fullWidth>
                <TextField
                    autoFocus
                    id="name"
                    variant="standard"
                    label="Event name"
                    value={event.name}
                    placeholder={defaultEventName}
                    onChange={e => this.setEventName(e.target.value)}
                    onKeyDown={e => processEnterKey(e, this.handleNext)}
                    InputProps={{ endAdornment }}
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '100%', maxWidth: NEW_EVENT_ELEM_WIDTH }}
                />
            </FormControl>
            <Spacing />
            <MaterialDate
                icon
                enableUnderline
                label={event.type === 'multiday' ? 'Event start date' : 'Event date'}
                value={event.date}
                onChange={this.setEventDate}
                format={EVENT_DATETIME_FORMAT_DLG}
                popperPlacement={'bottom'}
                style={{ width: '100%', maxWidth: NEW_EVENT_ELEM_WIDTH }}
            />
            <Spacing />
            <FormControl variant="standard" margin="dense" fullWidth>
                <Typography gutterBottom>Participants</Typography>
                <RadioGroup value={event.eventGender}
                    style={{ flexDirection: 'row' }}
                    onChange={e => this.setEventAndRoundsProp('eventGender', e.target.value as EventGender)}>
                    <ProgressRadio value={'both'} label={EVENT_GENDER_LABELS[0]} />
                    <ProgressRadio value={'men'} label={EVENT_GENDER_LABELS[1]} />
                    <ProgressRadio value={'women'} label={EVENT_GENDER_LABELS[2]} />
                </RadioGroup>
            </FormControl>
            <Spacing />
            <TextField
                variant="standard"
                id="description"
                label="Event Description (Optional)"
                value={event.description || ''}
                placeholder="Describe your event"
                onChange={e => this.setEventProp('description', Utils.toSafeString(e.target.value))}
                onKeyDown={e => processEnterKey(e, this.handleNext)}
                InputLabelProps={{ shrink: true }}
                style={{ width: '100%', maxWidth: NEW_EVENT_ELEM_WIDTH }} />
        </>;
    }

    private setPaymentSettingsEnableStatus = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
        e.stopPropagation();
        const { paymentSettings } = this.state;
        paymentSettings.enabled = value;
        this.setState({ paymentSettings });
    };

    private setPayeeEmail = (newEmail: string) => {
        const { paymentSettings } = this.state;
        paymentSettings.payeeEmailAddress = newEmail;
        this.setState({ paymentSettings });
    };

    private setFeeCostWithDescription = (newFeeCostStr: string, description?: string) => {
        const { paymentSettings } = this.state;
        paymentSettings.feeCost = parseFloat(newFeeCostStr) ?? 0;
        if (description) {
            paymentSettings.feeDescription = description;
        }
        this.setState({ paymentSettings });
    };

    private incorrectPaymentSettings = () => {
        const { paymentSettings } = this.state;
        return paymentSettings.enabled && !(paymentSettings.feeCost > 0 && paymentSettings.payeeEmailAddress.length > 0);
    };

    private updatePaymentSettings = (newPayPalEmailAddress: string, newPayPalCurrencyCode: string) => {
        const paymentSettings = this.state.paymentSettings;
        if (newPayPalEmailAddress) {
            paymentSettings.payeeEmailAddress = newPayPalEmailAddress.trim();
        }
        if (newPayPalCurrencyCode) {
            paymentSettings.currencyCode = newPayPalCurrencyCode.trim();
        }
        this.setState({ paymentSettings });
    };

    private courseSetupPage = () => null;

    private registrationPage = () => {
        const { event, rounds, paymentSettings } = this.state;
        if (!event.publicId) {
            return null;
        }
        const lessThanXDaysBeforeEvent = (event.date - Utils.roundDate(Date.now())) < REGISTRATION_DEADLINE_DAYS * Utils.DAY_MILLIS;
        const registeringByAdmin = (event.registrationByAdmin === undefined && lessThanXDaysBeforeEvent) || Boolean(event.registrationByAdmin);
        const disabled = registeringByAdmin;
        const textEditGolfers = 'Organizers can always edit registered golfers, and the shareable registration link is always available in the Event settings.';
        const textAddGolfers = 'Add participants yourself in the golfers section after creating an event.';
        const textEmail = 'Receive an email confirmation when golfers self-register via the registration page.';
        const textSchedule = 'Golfers will be added to the schedule in the order of joining the event. Edit, create or customize via the scheduling tab.';
        const typoAnyone =
            <Flex>
                Anyone with the event link &nbsp; <InfoIconTooltip title={textEditGolfers} placement={'bottom-start'} leaveTouchDelay={3000} />
            </Flex>;
        const typoOnly =
            <Flex>
                Only you &nbsp; <InfoIconTooltip title={textAddGolfers} offset1={-2} placement={'bottom'} leaveTouchDelay={3000} />
            </Flex>;
        const typoNotify =
            <Flex>
                Notify me via email when a new golfer registers &nbsp; <InfoIconTooltip offset1={-2} title={textEmail} leaveTouchDelay={3000} placement={'bottom'} disabled={disabled} />
            </Flex>;
        const typoSchedule = event.type === 'multiday' ?
            <Flex>Auto-Schedule for round 1 &nbsp; <InfoIconTooltip title={textSchedule} placement={'bottom-start'} leaveTouchDelay={3000} /></Flex> :
            <Flex>Automatically add new golfers to the schedule</Flex>;
        const registrationLink = Urls.makeAboutLink(event.publicId);
        const eventOrRoundDate = event.type === 'multiday' && rounds.length > 0 ? rounds[0].date : event.date;
        const lastEventMs = Utils.roundDate(eventOrRoundDate);
        return <>
            <FormControl variant="standard" margin="dense" fullWidth>
                <Typography>Who can add golfers to an event</Typography>
                <RegistrationRadioGroup
                    typo1={typoAnyone}
                    typo2={typoOnly}
                    disabled={disabled}
                    onOptionChanged={e => this.setEventProp('registrationByAdmin', e.target.value === 'true')}
                    registeringByAdmin={registeringByAdmin}
                    registrationLink={registrationLink}
                />
                <Spacing />
                <MaterialDate
                    icon
                    enableUnderline
                    disabled={disabled}
                    label="Registration deadline﻿ - through"
                    shouldDisableDate={date => disabled || date.isBefore(Date.now(), 'day') || date.isAfter(lastEventMs, 'day')}
                    value={getRegistrationDate(event)}
                    onChange={date => this.setEventProp('registrationDeadline', date.getTime())}
                    style={{ maxWidth: NEW_EVENT_ELEM_WIDTH }}
                />
                <Spacing />
                <FormControlLabel
                    disabled={disabled}
                    style={{ marginLeft: 4, color: disabled ? 'rgba(0, 0, 0, 0.38)' : '' }}
                    label={typoNotify}
                    control={<Checkbox
                        sx={{ alignSelf: 'start' }}
                        color="secondary"
                        disabled={disabled}
                        onChange={(e, v) => { e.stopPropagation(); this.setEventProp('registrationEmailNotify', v); }}
                        checked={Utils.isTrue(event.registrationEmailNotify, DEFAULT_REGISTRATION_EMAIL_NOTIFY)}
                    />}
                />
                <Spacing />
                <ParticipantsNumberField
                    disabled={disabled}
                    inputRef={this.inputRef}
                    maxGolfers={event.maxGolfers}
                    maxWidth={NEW_EVENT_ELEM_WIDTH}
                    onChange={(e, maxGolfers) => this.setEventProp('maxGolfers', Utils.parseInt(e.target.value, 1, maxGolfers) || undefined)}
                />
                <PayPalPaymentSettings
                    style={{ paddingLeft: 4, paddingTop: 8 }}
                    disabled={disabled}
                    onPayPalDialogSave={(newPayPalEmailAddress: string, newPayPalCurrencyCode: string) =>
                        this.updatePaymentSettings(newPayPalEmailAddress, newPayPalCurrencyCode)}
                    paymentSettings={paymentSettings}
                    setPaymentSettingsEnableStatus={this.setPaymentSettingsEnableStatus}
                    setPayeeEmail={this.setPayeeEmail}
                    setFeeCostWithDescription={this.setFeeCostWithDescription}
                />
                <FormControlLabel
                    style={{ marginLeft: 4, marginTop: 22 }}
                    label={typoSchedule}
                    control={<Checkbox
                        sx={{ alignSelf: 'start' }}
                        color="secondary"
                        onChange={(e, v) => { e.stopPropagation(); this.setEventProp('autoSchedule', v ? 'ON' : 'OFF'); }}
                        checked={event.autoSchedule === 'ON'}
                    />}
                />
            </FormControl>
            <Spacing />
        </>;
    };

    private setTeamSize = (teamSize: ScoringTeamSize, withBackUp: boolean = false) => {
        const { event, teamScoringFormat } = this.state;
        if (event.teamSize !== teamSize) {
            event.teamSize = teamSize;
            const handicapAllowances = this.evalDefaultHandicapAllowances(teamSize, teamScoringFormat);
            if (withBackUp) {
                this.setState({ event, teamSizeBackup: teamSize, handicapAllowances });
            } else {
                this.setState({ event, handicapAllowances });
            }
        }
    };

    private handleHandicapPercentsChange = (num: number, val: number) => {
        let { handicapAllowances } = this.state;
        if (handicapAllowances[num] !== val) {
            handicapAllowances[num] = val;
            this.setState({ handicapAllowances });
        }
    };

    private evalDefaultHandicapAllowances = (teamSize: ScoringTeamSize, teamScoringFormat: ScoringFormat) => {
        if (teamScoringFormat === ScoringFormatTeams.best_ball) {
            return fillSameHandicaps(85);
        } else if (teamScoringFormat === ScoringFormatTeams.alternate_shot) {
            return fillAlternateHandicaps();
        } else if (teamScoringFormat === ScoringFormatTeams.chapman) {
            return fillChapmanHandicaps();
        } else {
            return fillProportionalHandicaps(teamSize);
        }
    };

    private onTeamScoringFormatChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { event } = this.state;
        const teamScoringFormat = e.target.value as ScoringFormatTeams | ScoringFormatSkins.skins_team;
        if (teamScoringFormat === 'alternate_shot' || teamScoringFormat === 'chapman') {
            event.teamSize = 2;
        }
        const handicapAllowances = this.evalDefaultHandicapAllowances(event.teamSize, teamScoringFormat);
        const descriptionType = 'scoringFormat';
        this.setState({ event, teamScoringFormat, handicapAllowances, descriptionType });
    };

    private onIndividualScoringFormatChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { event } = this.state;
        const teamMode = event.teamSize > 1;
        const individualScoring = e.target.value as ScoringFormatIndividual | ScoringFormatSkins.skins_individual;
        const descriptionType = teamMode ? 'scoringType' : 'scoringFormat';
        this.setState({ individualScoring, descriptionType });
    };

    private onScoringModeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const scoringMode = e.target.value as ScoringMode;
        const descriptionType = 'scoringMode';
        this.setState({ scoringMode, descriptionType })
    };

    private showPointSettings = () => this.setState({ showPointSettings: true });

    private scoringPage = () => {
        const { event, teamSizeBackup, individualScoring, teamScoringFormat, scoringMode, showPointSettings,
            stablefordPoints, handicapAllowances, descriptionType } = this.state;
        const teamMode = event.teamSize > 1;
        const textNote = 'You can add multiple competitions and configure details in the Scoring tab after the event is created.';
        const scoringFormat = teamMode ? teamScoringFormat : individualScoring;
        let description: Array<string> | undefined;
        let scoringTitle: ScoringFormatIndividual | ScoringFormatTeams | ScoringFormatSkins | ScoringMode | ScoringType | undefined;
        if (descriptionType === 'scoringFormat') {
            description = scoringFormatDescriptions.get(scoringFormat);
            scoringTitle = scoringFormat;
        }
        if (descriptionType === 'scoringMode') {
            description = scoringModeDescriptions.get(scoringMode);
            scoringTitle = scoringMode;
        }
        if (descriptionType === 'scoringType') {
            scoringTitle = individualScoring === ScoringFormatIndividual.strokeplay ?
                ScoringFormatIndividual.strokeplay : ScoringFormatIndividual.stableford;
            description = scoringTypeDescriptions.get(scoringTitle);
        }
        const isNet = isNetMode(scoringMode);
        const handicapUserNum = isNet ? teamMode ? event.teamSize : 1 : 0;
        const availableTeamSizes = scoringFormat === 'alternate_shot' || scoringFormat === 'chapman' ? [2] : SCORING_TEAM_SIZES;
        return <>
            <ButtonGroup size="small">
                <AppButton
                    variant="contained"
                    color={!teamMode ? 'secondary' : 'info'}
                    style={{ width: 150 }}
                    onClick={() => this.setTeamSize(1)}>
                    Individual
                </AppButton>
                <AppButton
                    variant="contained"
                    color={teamMode ? 'secondary' : 'info'}
                    style={{ width: 150 }}
                    onClick={() => this.setTeamSize(teamSizeBackup)}>
                    Team
                </AppButton>
            </ButtonGroup>
            <Grid container>
                <Grid item xs={7}>
                    {teamMode && <FormControl variant="standard" margin="dense" fullWidth>
                        <Typography gutterBottom>Team format</Typography>
                        <RadioGroup value={teamScoringFormat}
                            style={{ flexDirection: 'row' }}
                            onChange={this.onTeamScoringFormatChanged}>
                            {TEAM_SCORINGS_AND_SKINS.map(scoringFormat =>
                                <ScoringFormatRadio key={scoringFormat}
                                    width={160}
                                    teamMode={teamMode}
                                    scoringFormat={scoringFormat}
                                    selectedScoringFormat={teamScoringFormat} />)}
                        </RadioGroup>
                    </FormControl>}
                    <FormControl variant="standard" margin="dense" fullWidth>
                        <Typography gutterBottom>Scoring</Typography>
                        <RadioGroup value={individualScoring}
                            style={{ flexDirection: teamMode ? 'row' : 'column' }}
                            onChange={this.onIndividualScoringFormatChanged}>
                            {(teamMode ? INDIVIDUAL_SCORINGS : INDIVIDUAL_SCORINGS_AND_SKINS).map(scoringFormat =>
                                <ScoringFormatRadio key={scoringFormat}
                                    teamMode={teamMode}
                                    scoringFormat={scoringFormat}
                                    selectedScoringFormat={individualScoring}
                                    onClick={this.showPointSettings} />)}
                        </RadioGroup>
                    </FormControl>
                    {teamMode && <FormControl variant="standard" margin="dense" fullWidth>
                        <Typography gutterBottom>Number of players in a team</Typography>
                        <RadioGroup value={String(event.teamSize)}
                            style={{ flexDirection: 'row' }}
                            onChange={e => this.setTeamSize(Number.parseInt(e.target.value) as ScoringTeamSize, true)}>
                            {availableTeamSizes.map(teamSize => <ProgressRadio key={String(teamSize)}
                                width={110}
                                value={String(teamSize)}
                                label={`${teamSize} people`} />)}
                        </RadioGroup>
                    </FormControl>}
                    <FormControl variant="standard" margin="dense" fullWidth>
                        <Typography gutterBottom>Handicaps</Typography>
                        <RadioGroup value={scoringMode}
                            style={{ flexDirection: 'row' }}
                            onChange={this.onScoringModeChanged}>
                            {ScoringModes.map(scoringMode => <ProgressRadio key={scoringMode}
                                width={100}
                                value={scoringMode}
                                label={Utils.makeFriendlyString(scoringMode, true)} />)}
                        </RadioGroup>
                    </FormControl>
                    {isNet && <>
                        {handicapUserNum > 0 && <HandicapPercentSelector value={handicapAllowances[0]} index={0} handlePercentsChange={this.handleHandicapPercentsChange} teamSize={event.teamSize} individual={handicapUserNum === 1} />}
                        {handicapUserNum > 1 && <HandicapPercentSelector value={handicapAllowances[1]} index={1} handlePercentsChange={this.handleHandicapPercentsChange} teamSize={event.teamSize} />}
                        {handicapUserNum > 2 && <HandicapPercentSelector value={handicapAllowances[2]} index={2} handlePercentsChange={this.handleHandicapPercentsChange} teamSize={event.teamSize} />}
                        {handicapUserNum > 3 && <HandicapPercentSelector value={handicapAllowances[3]} index={3} handlePercentsChange={this.handleHandicapPercentsChange} teamSize={event.teamSize} />}
                    </>}
                    {isNet && <HandicapInfoIcon />}
                    {showPointSettings && <StablefordPointSettingsDialog
                        open={true}
                        event={event}
                        points={(stablefordPoints || Scoring.defaultStablefordPoints).slice()}
                        savePoints={stablefordPoints => this.setState({ showPointSettings: false, stablefordPoints })}
                        onClose={() => this.setState({ showPointSettings: false })} />}
                </Grid>
                <Grid item xs={5}>
                    {description?.length && scoringTitle &&
                        <div style={{ backgroundColor: AppColors.descriptionBkColor, padding: 8, marginTop: 8 }}>
                            <Typography variant="subtitle2">{Utils.makeFriendlyString(scoringTitle, true)}</Typography>
                            {description.map((text, idx) =>
                                <Typography key={idx} sx={{ fontSize: 12, lineHeight: '18px' }}
                                    variant="caption">{text}{idx < description!.length - 1 ? <br /> : ''}
                                </Typography>)}
                        </div>}
                    <Typography sx={{ fontSize: 12, lineHeight: '18px', color: AppColors.webGrey }} variant="caption">
                        {textNote}
                    </Typography>
                </Grid>
            </Grid>
        </>;
    }

    private userRemoteChanged = (userInfo: UserInfo) => {
        const paymentSettings = this.state.paymentSettings;
        paymentSettings.payeeEmailAddress = userInfo.payPalEmailAddress ?? '';
        paymentSettings.currencyCode = userInfo.payPalCurrencyCode ?? '';
        this.setState({ paymentSettings });
    };

    private addRound = () => {
        const { event, rounds } = this.state;
        rounds.push(createRound(event, rounds));
        this.setState({ rounds });
    }

    private setPracticeRound = (practiceRound: boolean) => this.setState({ practiceRound });

    private fixEvenDate = (rounds: Array<Round>) => {
        const { event } = this.state;
        if (event.type === 'multiday' && rounds.length > 0) {
            const { minDate, maxDate } = getRoundDates(rounds);
            event.date = minDate;
            event.finishDate = maxDate;
        }
        const eventOrRoundDate = event.type === 'multiday' && rounds.length > 0 ? rounds[0].date : event.date;
        if (event.registrationDeadline && event.registrationDeadline > eventOrRoundDate) {
            event.registrationDeadline = eventOrRoundDate;
        }
        this.setState({ event });
    }

    private deleteRound = (round: Round) => {
        const { event, rounds } = this.state;
        rounds.splice(round.roundOrder - 1, 1);
        rounds.forEach((r, idx) => r.roundOrder = idx + 1);
        this.fixEvenDate(rounds);
        this.setState({ event, editedRound: undefined });
    };

    private saveRound = (round: Round, dates: Array<number>) => {
        const { rounds } = this.state;
        rounds[round.roundOrder - 1] = { ...round };
        updateRoundDates(rounds, dates, []);
        this.fixEvenDate(rounds);
        this.setState({ rounds, editedRound: undefined });
    };

    private roundsSetupPage = () => {
        const { event, rounds, editedRound, practiceRound } = this.state;
        const canAddMore = rounds.length < MAX_ROUNDS;
        return <>
            <Paper sx={{ overflow: 'auto', boxShadow: 'none' }}>
                <List>
                    {rounds.map(round =>
                        <RowRoundItem
                            key={round.roundOrder}
                            event={event}
                            round={round}
                            clickHandler={selectedRound => this.setState({ editedRound: structuredClone(selectedRound) })} />)}
                </List>
                {canAddMore &&
                    <AppButton color="info" onClick={this.addRound}
                        style={{ width: '100%', height: 40, boxShadow: 'none' }}>
                        <PlusIcon /> Add round
                    </AppButton>}
            </Paper>
            {canAddMore && <Spacing />}
            <PracticeRoundPage
                autoFocus={practiceRound}
                practiceRound={practiceRound}
                setPracticeRound={this.setPracticeRound}
                practiceRoundInfo={event.practiceRoundInfo ?? ''}
                setPracticeRoundInfo={val => this.setEventProp('practiceRoundInfo', val)}
            />
            {editedRound && <RoundEditDialog
                rounds={rounds}
                round={editedRound}
                prevRound={rounds.find(r => r.roundOrder === editedRound.roundOrder - 1)}
                deleteRound={this.deleteRound}
                saveRound={this.saveRound}
                close={() => this.setState({ editedRound: undefined })}
            />}
        </>;
    }

    private setOrgType = (eventOrgType: EventOrgType) => {
        const { event, rounds } = this.state;
        const pages = eventOrgType === 'single-day' ?
            [
                this.eventDetailsPage,
                this.courseSetupPage,
                this.registrationPage,
                this.scoringPage,
            ] : [
                this.eventDetailsPage,
                this.courseSetupPage,
                this.scoringPage,
                this.roundsSetupPage,
                this.registrationPage,
            ];
        const steps: Array<PageType> = eventOrgType === 'single-day' ?
            [
                'Event details',
                'Course setup',
                'Registration',
                'Scoring'
            ] : [
                'Event details',
                'Course',
                'Scoring',
                'Rounds',
                'Registration'
            ];
        if (eventOrgType === 'multi-day') {
            event.type = 'multiday';
            rounds.push(createRound(event, rounds));
        }
        this.setState({ eventOrgType, pages, steps, event, rounds });
    }

    render() {
        const { userAware, noDuplicate } = this.props;
        const { event, rounds, pages, steps, eventOrgType, currentStep, coursesActivated, practiceRound } = this.state;
        if (!eventOrgType) {
            return (
                <SMMobileDialog open onClose={this.handleClose} maxWidth={'xs'} >
                    <DialogAppBar close={this.handleClose} label="Create new event" toolbarBarStyle={{}} />
                    <DialogContent>
                        <Typography>
                            Please, select the type of event you are organizing:
                        </Typography>
                        <Spacing />
                        <AppButton
                            color="secondary"
                            style={{ width: '100%' }}
                            onClick={() => this.setOrgType('single-day')}>
                            Single-day
                        </AppButton>
                        <Spacing height={8} />
                        <AppButton
                            color="info"
                            style={{ width: '100%', backgroundColor: AppColors.webGreen500, color: AppColors.white, padding: '6px 6px' }}
                            onClick={() => this.setOrgType('multi-day')}>
                            <ProBadge small inv />
                            <span style={{ flex: 1 }} />
                            Multi-day
                            <span style={{ flex: 1 }} />
                            <ProBadge small placeholder />
                        </AppButton>
                        <Spacing height={8} />
                        <AppButton
                            disabled={noDuplicate}
                            style={{ width: '100%', border: 'none', backgroundColor: AppColors.white, color: AppColors.webBlue500 }}
                            onClick={this.handleDuplicateEvent}>
                            <ProBadge small /> &nbsp; Duplicate an existing event
                        </AppButton>
                    </DialogContent>
                </SMMobileDialog>
            );
        }
        if (!userAware.hasPro && eventOrgType !== 'single-day') {
            const handleClosePorPlan = (name?: AccountActions) => {
                this.props.handleClose();
                accountAction(userAware, name);
            }
            return <ProPlanDialog handleClose={handleClosePorPlan} />;
        }
        const currentPage = pages[currentStep]();
        const nextBtnLabel = currentStep < steps.length - 2 ? 'Next' : currentStep === steps.length - 2 ? 'Last step' : 'Create event';
        const nextDisabled =
            (steps[currentStep] === 'Course setup' || steps[currentStep] === 'Course') && this.courseIsMissing() ||
            steps[currentStep] === 'Registration' && this.incorrectPaymentSettings() ||
            steps[currentStep] === 'Rounds' && (!rounds?.length || practiceRound && !event.practiceRoundInfo?.trim() || this.coursesAreMissing());
        const dlgTitle = eventOrgType === 'single-day' ?
            'Create single-day event' :
            <Flex>Create multi-day event &nbsp; <ProBadge small /></Flex>;
        const visibleCourseSetupPage = steps[currentStep] === 'Course setup' || steps[currentStep] === 'Course';
        return (
            <SMMobileDialog open onClose={this.handleCloseNoBackdrop} maxWidth={'md'}>
                <DialogAppBar bar close={this.handleClose} label={dlgTitle} toolbarBarStyle={{ padding: 0, marginLeft: 16, marginRight: 16 }} />
                <DialogAppBar><ProgressBar steps={steps} selectedStep={currentStep} /></DialogAppBar>
                <DialogContent style={{ minHeight: 380 }}>
                    {currentPage}
                    <CourseSetupPage
                        eventOrRound={event}
                        ref={this.courseSetupPageRef}
                        visible={visibleCourseSetupPage}
                        courseUpdated={this.courseUpdated}
                        coursesActivated={coursesActivated} />
                </DialogContent>
                <DialogActions>
                    {currentStep > 0 && <AppButton color="info" onClick={this.handleBack}>Back</AppButton>}
                    <span style={{ flex: '1 1 0%' }} />
                    <AppButton onClick={this.handleNext} color="secondary" disabled={nextDisabled}>{nextBtnLabel}</AppButton>
                </DialogActions>
                <FirebaseUserDataComponent onData={this.userRemoteChanged} />
            </SMMobileDialog >
        );
    }
}

function NewEventDialogDetailedFC(props: Omit<Props, 'userAware'>) {
    const userAware = useUserAware();
    return userAware.workingUserId ? <NewEventDialogDetailed userAware={userAware}   {...props} /> : null;
}

export default NewEventDialogDetailedFC;
