import * as React from 'react';
import axios, { AxiosRequestConfig } from "axios";
import { XSMobileDialog } from '../common/dialog/MobileDialog';
import AppButton from '../common/components/AppButton';
import DialogAppBar from "../common/dialog/DialogAppBar";
import { AppColors } from "../main/Theme";
import { Box, FormControl, Typography, DialogContent } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { showProgress, NoticeElement } from '../redux/ReduxConfig';
import { Urls } from "../util/config";

interface ManageAccountProps {
    email: string,
    handleLogout: () => void
}

const requestConfig: AxiosRequestConfig = {
    headers: {
        accept: 'application/json'
    },
    timeout: 10000
};

class ManageAccountDialog extends React.Component<ManageAccountProps & DialogProps & WithStyles<typeof styles>> {

    private handleRequestPasswordReset = () => {
        const { email } = this.props;
        const hideProgress = showProgress('ManageAccountDialog');
        axios.post(Urls.requestPwdReset, { type: 'EMAIL', id: email }, requestConfig)
            .then(() => hideProgress('Password reset email has been sent.'))
            .catch((error) => hideProgress(JSON.stringify(error)));
    };

    render() {
        const { classes, handleLogout, email } = this.props;
        return (
            <XSMobileDialog open={this.props.open} maxWidth={'xs'} fullWidth={true} onClose={this.props.onClose} >
                <DialogAppBar label="Manage account" close={() => this.props.onClose ? this.props.onClose({} as React.SyntheticEvent<{}>, 'backdropClick') : {}} />
                <DialogContent>
                    <Typography color={AppColors.webGrey} variant={'caption'}>You are logged in as</Typography>
                    <Typography color={AppColors.webBlack} variant={'h6'}>{email}</Typography>
                    <Box sx={{backgroundColor: AppColors.webGreyLight, marginTop: 1}} display="flex" flexDirection="row" borderRadius={1} padding={1}>
                        <Typography sx={{ width: '100%'}}>Forgot your password? <a rel="noopener noreferrer" style={{ cursor: 'pointer' }} className={classes.linkBlue} onClick={() => this.handleRequestPasswordReset()}>Request password reset</a></Typography>                        
                        <NoticeElement>
                            You will receive an email to {email} with password reset instructions. if you do not see it within 5 minutes, please be sure to check the spam or junk folder.
                        </NoticeElement>
                    </Box>
                    <FormControl variant="standard" margin="dense">
                        <AppButton color="info" onClick={() => handleLogout()}>Log out</AppButton>
                    </FormControl>
                </DialogContent>
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(ManageAccountDialog);
