import * as React from 'react';
import { DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { Round, Tee, Course, HolesType, calculateRoundDates, setEventDate } from '../../types/EventTypes';
import { MONTH_WEEK_DATE_FORMAT, MONTH_DATE_FORMAT, DEBUG } from '../../util/config';
import MaterialDate from '../../common/MaterialDate';
import { XSMobileDialog } from '../../common/dialog/MobileDialog';
import DialogAppBar from '../../common/dialog/DialogAppBar';
import AppButton from '../../common/components/AppButton';
import { Flex, Spacing } from '../../common/Misc';
import { CourseSetupPage } from './CourseSetupPage';
import { formatDateUniversal } from '../Event';
import { InfoIcon } from 'src/common/Icons';
import { Box } from '@mui/system';
import { AppColors } from 'src/main/Theme';
import { NoticeElement, showAlertProps } from 'src/redux/ReduxConfig';

type Props = {
    round: Round;
    prevRound?: Round;
    rounds: Array<Round>;
    close: () => void;
    saveRound: (round: Round, dates: Array<number>) => void;
    deleteRound: (round: Round) => void;
}

type State = {
    editedRound: Round;
    updatePrompt: boolean;
}

export class RoundEditDialog extends React.Component<Props, State> {
    state: State = {
        editedRound: { ...this.props.round },
        updatePrompt: false
    }

    private readonly courseSetupPageRef: React.RefObject<CourseSetupPage> = React.createRef();

    public roundCourseIsMissing = () => this.courseSetupPageRef.current?.courseIsMissing();
    public closePopups = () => this.courseSetupPageRef.current?.closePopups();

    private setRoundDate = (date: Date) => {
        const { editedRound } = this.state;
        if (editedRound) {
            setEventDate(editedRound, date);
            this.setState({ editedRound });
        }
    };

    private onCloseCourseSetup = (_e: string, reason: string) => {
        if (this.closePopups()) {
            return;
        }
        if ("backdropClick" === reason) {
            return;
        }
        const { close } = this.props;
        close();
    };

    private roundCourseUpdated = (course?: Course, holesType?: HolesType, teeMen?: Tee, teeWomen?: Tee) => {
        const { editedRound } = this.state;
        if (editedRound) {
            editedRound.course = course;
            editedRound.holesType = holesType;
            editedRound.teeMen = teeMen;
            editedRound.teeWomen = teeWomen;
            this.setState({ editedRound });
        }
    };

    private saveRound = async () => {
        const { saveRound, rounds } = this.props;
        const { editedRound } = this.state;
        const { dates, changes } = calculateRoundDates(editedRound.date, editedRound, rounds);
        if (changes === 1) {
            saveRound(editedRound, dates);
        } else {
            this.setState({ updatePrompt: true });
        }
    }

    private handleDeleteRound = () => {
        const { deleteRound } = this.props;
        const { editedRound } = this.state;
        showAlertProps({
            content: 'This round will be permanently deleted, including all settings. This operation can not be un-done. Click CONFIRM to proceed with deletion or CANCEL to keep this round.',
            appBarLabel: 'Confirm permanent delete',
            buttons: [
                { title: 'Cancel' },
                { title: 'Confirm and delete', color: 'secondary', action: () => deleteRound(editedRound) }
            ]
        });
    }

    render() {
        const { rounds, prevRound, close, saveRound } = this.props;
        const { editedRound, updatePrompt } = this.state;
        const saveDisabled = this.roundCourseIsMissing();
        const label = DEBUG ? <>
            Round {editedRound.roundOrder}
            <span style={{ fontWeight: 400, fontSize: 11, color: 'yellow', position: 'absolute', top: '0' }}>{editedRound.id}</span>
        </> : `Round ${editedRound.roundOrder}`;
        const minDate = prevRound ? prevRound.date : undefined;
        const { dates, datesOneChanged } = calculateRoundDates(editedRound.date, editedRound, rounds);
        const closeUpdatePrompt = () => this.setState({ updatePrompt: false });
        return <>
            <XSMobileDialog open={true} onClose={this.onCloseCourseSetup} maxWidth={'xs'}>
                <DialogAppBar bar close={close} label={label} />
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sx={{ paddingRight: '8px' }}>
                            <MaterialDate
                                icon
                                autoFocus
                                enableUnderline
                                label="Round date"
                                style={{ width: '100%' }}
                                value={editedRound.date}
                                minDate={minDate}
                                onChange={this.setRoundDate}
                                format={MONTH_WEEK_DATE_FORMAT}
                                popperPlacement="bottom"
                            />
                        </Grid>
                    </Grid>
                    <Spacing />
                    <CourseSetupPage
                        visible
                        coursesActivated
                        eventOrRound={editedRound}
                        course={editedRound.course}
                        holesType={editedRound.holesType}
                        defaultMenTee={editedRound.teeMen?.id}
                        defaultWomenTee={editedRound.teeWomen?.id}
                        courseUpdated={this.roundCourseUpdated}
                        ref={this.courseSetupPageRef}
                    />
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" onClick={() => this.handleDeleteRound()}>Delete round</AppButton>
                    <span style={{ flex: '1 1 0%' }} />
                    <AppButton onClick={close} color="info">Cancel</AppButton>
                    <AppButton onClick={this.saveRound} color="secondary" disabled={saveDisabled}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
            <XSMobileDialog open={updatePrompt} onClose={closeUpdatePrompt} maxWidth={'xs'}>
                <DialogAppBar bar close={closeUpdatePrompt} label={'Update Round dates'} />
                <DialogContent>
                    <Typography sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '150%' }} component={'span'}>
                        Would you like to update the dates for the other rounds in this multi-day event too?
                    </Typography>
                    <Spacing height={4} />
                    <Box sx={{ backgroundColor: AppColors.webGrey100, padding: '8px', borderRadius: '4px' }}>
                        <Flex>
                            <NoticeElement>
                                The round order will be maintained. If date changes conflict with this order and you choose not to update all, the rounds will be renumbered.
                            </NoticeElement>
                            &nbsp;
                            <Typography sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '150%' }} component={'span'}>
                                The new dates will be:
                            </Typography>
                        </Flex>
                        {dates.map((newDate, idx) =>
                            <Flex key={idx}>
                                <InfoIcon sx={{ visibility: 'hidden' }} />&nbsp;
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Roboto', fontWeight: idx + 1 === editedRound.roundOrder ? 600 : 500, lineHeight: '150%' }} component={'span'}>
                                    Round {idx + 1}: {formatDateUniversal(newDate, MONTH_DATE_FORMAT)}
                                </Typography>
                            </Flex>)}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <AppButton color="info" onClick={closeUpdatePrompt}>Cancel</AppButton>
                    <span style={{ flex: '1 1 0%' }} />
                    <AppButton onClick={() => saveRound(editedRound, datesOneChanged)} color="info">Update Round {editedRound.roundOrder} only</AppButton>
                    <AppButton onClick={() => saveRound(editedRound, dates)} color="secondary">Update all</AppButton>
                </DialogActions>
            </XSMobileDialog>
        </>;
    }
}
