import * as React from 'react';
import { EventBase, Tee, isCompoundCourse, Course, getCourseName, EventData, Round, allEvents, isRound, Event, rollEvents } from 'src/types/EventTypes';
import { Typography } from '@mui/material';
import { fullName } from 'src/contact/Contact';
import { formatDateUniversal, formatEventDateAndTime } from '../Event';
import { MONTH_DATETIME_FORMAT, TIME_FORMAT } from 'src/util/config';

const Ty = ({ label, children }: { label: string, children: React.ReactNode }) => <Typography><b>{label}:</b> {children}</Typography>;

const CourseRawDetails = ({ course }: { course: Course }) => {
    return <>
        <Ty label="Course name">{getCourseName(course)}</Ty>
        <Ty label="Course is compound">{isCompoundCourse(course) ? 'TRUE' : 'FALSE'}</Ty>
    </>;
};

const TeeRawDetails = ({ name, tee }: { name: string, tee?: Tee }) => {
    if (tee) {
        return <>
            <Ty label={`tee`}>{name} - {tee.name} - {tee.id}</Ty>
            <pre>
                par: [{tee.par.join('][')}]
            </pre>
            <pre>
                hcp: [{tee.handicap.join('][')}]
            </pre>
        </>;
    } else {
        return <>
            <Ty label={`tee`}>{name} - N/A</Ty>
        </>;
    }
}

const CompetitionRawDetails = ({ eventOrRound, eventData }: { eventOrRound: EventBase, eventData: EventData }) => {
    const competitons = eventData.competitionsMap.get(eventOrRound.id) ?? [];
    const namePrefix = isRound(eventOrRound) ? `Round ${eventOrRound.roundOrder} (${eventOrRound.id})` : '';
    return <>
        <TeeRawDetails name={'men'} tee={eventOrRound.teeMen} />
        <TeeRawDetails name={'women'} tee={eventOrRound.teeWomen} />
        {competitons.map((competiton, idx) => <div key={idx}>
            <Ty label={`${namePrefix} competiton`}>{competiton.name ?? ''} {competiton.scoring.format} {competiton.scoring.mode}</Ty>
        </div>)}
    </>;
};

export class KeyTable<T> {
    public rows = new Map<string, Array<T>>();
    public cols = new Map<string, number>();
    constructor(mains: Array<string>) {
        this.initColumns(mains);
    }
    public initColumns(mains: Array<string>) {
        mains.forEach((main, idx) => this.cols.set(main, idx));
    }
    public setValue(col: string, row: string, value: T) {
        if (!this.rows.has(row)) {
            this.rows.set(row, Array.from(Array(this.cols.size)));
        }
        const r = this.rows.get(row)!;
        r[this.cols.get(col)!] = value;
    }
    public array() {
        return Array.from(this.rows.keys());
    }
    public values(row: string) {
        return this.rows.get(row)!;
    }
}

export const EventRawDetails = ({ eventData }: { eventData: EventData }) => {
    const { event, rounds, golfersMap, golfersAggregated } = eventData;
    const props: Array<keyof Event> = [
        'id', 'type', 'name', 'userId', 'publicId', 'userName', 'userEmail', 'teamSize',
        'eventGender', 'date', 'dateUTC', 'lastModified', 'hideLiveScores', 'badgeUrl',
        'bannerUrl', 'legitDate', 'legitAppDate', 'handicapSystem', 'holesType'
    ];
    const propsRound: Array<keyof Round> = [
        'id', 'type', 'name', 'eventId', 'roundOrder',
        'userId', 'publicId', 'userName', 'userEmail', 'teamSize',
        'eventGender', 'date', 'dateUTC', 'lastModified', 'handicapSystem', 'holesType'
    ];
    const events = allEvents(event, rounds);
    const roundData = new KeyTable<any>(events.map(e => e.id));
    rounds.forEach(round => propsRound.forEach(prop => roundData.setValue(round.id, prop, round[prop])));
    const data = new KeyTable<string>(events.map(e => e.id));
    golfersMap.forEach((golfers, eventOrRoundId) =>
        golfers.forEach(golfer => data.setValue(eventOrRoundId, golfer.id, fullName(golfer) + (golfer.hidden ? ' (hidden)' : ''))));
    return <div style={{ padding: 20 }}>
        <Typography variant='h5'>Event details summary</Typography>
        {props.map((prop, idx) => <Ty key={idx} label={prop}>{(event as any)[prop]}</Ty>)}
        <Ty label='formatted date'>{formatEventDateAndTime(event, rounds, true, false)}</Ty>
        <Ty label='formatted tee time'>{formatDateUniversal(event.teeTime.startTime ?? 0, TIME_FORMAT)}</Ty>
        <Ty label='formatted lastModified'>{formatDateUniversal(event.lastModified ?? 0, MONTH_DATETIME_FORMAT)}</Ty>
        {event.course ?
            <CourseRawDetails course={event.course} /> :
            <Ty label='course'>N/A</Ty>}
        {rounds.map((round, idx) => <div key={idx}>
            {propsRound.map((prop, idx) => <Ty key={idx} label={`[round ${round.roundOrder}] ${prop}`}>{(round as any)[prop]}</Ty>)}
        </div>)}
        Rounds:
        <table>
            <tbody>
                {roundData.array().map((row, idx) =>
                    <tr key={idx}>
                        <td>{row}</td>
                        {roundData.values(row).map((value, idxd) => <td key={idxd}>{value}</td>)}
                    </tr>)}
            </tbody>
        </table>
        {rollEvents(event, rounds).map((eventOrRound, idx) =>
            <CompetitionRawDetails key={idx} eventOrRound={eventOrRound} eventData={eventData} />)}
        <table>
            <tbody>
                <tr>
                    <td>-</td>
                    {events.map((e, idxd) => <td key={idxd}>{isRound(e) ? `Round ${e.roundOrder}` : `Event`}</td>)}
                    <td>Aggregated</td>
                </tr>
                {data.array().map((row, idx) =>
                    <tr key={idx}>
                        <td>{row}</td>
                        {data.values(row).map((value, idxd) => <td key={idxd}>{value}</td>)}
                        <td>{fullName(golfersAggregated.get(row) ?? { id: row, lastName: '', hidden: false })}</td>
                    </tr>)}
            </tbody>
        </table>
    </div>;

};
