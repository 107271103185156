import * as React from 'react';
import { DialogActions, Divider, TextField, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DialogProps } from '@mui/material/Dialog';
import { EventBase, isEvent } from '../../../types/EventTypes';
import { XSMobileDialog } from '../../../common/dialog/MobileDialog';
import DialogAppBar from '../../../common/dialog/DialogAppBar';
import AppButton from '../../../common/components/AppButton';
import { Container, Item, Spacing } from '../../../common/Misc';
import * as Utils from '../../../util/utility';
import { styles } from '../../../styles';

type Props = {
    event: EventBase;
    points: Array<number>;
    savePoints: (points: Array<number>) => void;
} & WithStyles<typeof styles> & DialogProps;

type State = {
    points: Array<string>;
    focusedIdx: number;
};

const POINT_LABELS_SHARED = [
    'Bogey or one over par',
    'Par',
    'Birdie or one under par',
    'Eagle or two under par',
    'Double Eagle or three under par',
    'Four under par'
];

export const POINT_LABELS = [
    'Double Bogey or worse',
    ...POINT_LABELS_SHARED
];

export const LL_POINT_LABELS = [
    'Triple bogey or worse',
    'Double Bogey or two over par',
    ...POINT_LABELS_SHARED
];

export const STABLEFORD_MIN_POINT = -10;
export const STABLEFORD_MAX_POINT = 20;

function isValidPoint(value: string) {
    const d = parseInt(value, 10);
    return Utils.isValidNumeric(d) && d >= STABLEFORD_MIN_POINT && d <= STABLEFORD_MAX_POINT;
}

const Header = withStyles(styles)((props: WithStyles<typeof styles>) => {
    const { classes } = props;
    return (
        <Container>
            <Item xs={2} noWrap className={classes.boldText} placeCenter>Points</Item>
            <Item xs={10} noWrap className={classes.boldText}>Strokes taken in relation to par</Item>
        </Container>
    );
});

type RowProps = {
    point: string;
    index: number;
    focused: boolean;
    error: boolean;
    appMode: boolean;
    handleKey: (index: number, key: string) => void;
    handlePoint: (index: number, value: string) => void;
    handleFocus: (index: number) => void;
};

const Row = withStyles(styles)((props: RowProps & WithStyles<typeof styles>) => {
    const { point, index, focused, error, handleKey, handlePoint, handleFocus, appMode } = props;
    function setFocus(ip: HTMLElement | null) {
        if (ip && focused) {
            ip.focus();
        }
    }
    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
            handleKey(index, e.key);
            //        } else if (!(e.key === '-' || /[0-9]/.test(e.key) || e.keyCode < 32 || e.altKey && e.ctrlKey)) {
            //            e.preventDefault();
        }
    }
    function onFocus(_: React.FocusEvent<HTMLInputElement>) {
        handleFocus(index);
    }
    return (
        <Container>
            <Item xs={2} noWrap placeCenter>
                <TextField
                    variant="standard"
                    value={point}
                    error={error}
                    style={{ width: 40 }}
                    inputProps={{ onKeyDown, onFocus }}
                    onChange={e => handlePoint(index, e.target.value)}
                    inputRef={ip => setFocus(ip)} />
            </Item>
            <Item xs={10} noWrap>{appMode ? LL_POINT_LABELS[index] : POINT_LABELS[index]}</Item>
        </Container>
    );
});

class StablefordPointSettingsDialog extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            focusedIdx: 0,
            points: props.points.map(i => '' + i)
        };
    }

    private handleClose = () => {
        const close = this.props.onClose;
        if (close) {
            close({} as React.SyntheticEvent<any>, 'escapeKeyDown');
        }
    }

    private handleSave = () => {
        const { savePoints } = this.props;
        const { points } = this.state;
        savePoints(points.map(s => parseInt(s)));
    }

    private handlePoint = (index: number, val: string) => {
        const { event } = this.props;
        const { points } = this.state;
        const appStablefordSettingsMode = Boolean(isEvent(event) && (event.type === 'leaderboard' || event.leaderboard) && points.length === 8);
        const normIndex = index + (appStablefordSettingsMode ? 0 : 1);
        points[normIndex] = val;
        this.setState({ points });
    }

    private handleKey = (index: number, key: string) => {
        const { points, focusedIdx } = this.state;
        if ((key === 'ArrowUp' || key === 'ArrowLeft') && focusedIdx > 0) {
            this.setState({ focusedIdx: focusedIdx - 1 });
        } else if ((key === 'ArrowDown' || key === 'ArrowRight') && focusedIdx < points.length - 1) {
            this.setState({ focusedIdx: focusedIdx + 1 });
        }
    }

    render() {
        const { classes, event } = this.props;
        const { points, focusedIdx } = this.state;
        const appStablefordSettingsMode = Boolean(isEvent(event) && (event.type === 'leaderboard' || event.leaderboard) && points.length === 8);
        const isValid = points.reduce<boolean>((a, p) => a && isValidPoint(p), true);
        const normPoints = appStablefordSettingsMode ? points : points.slice(1);
        while (normPoints.length < 7){
            normPoints.push('0');
        };        
        return (
            <XSMobileDialog open={this.props.open} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
                <DialogAppBar label="Stableford point settings" close={this.handleClose} />
                <div style={{ padding: 16 }}>
                    <Typography variant="subtitle2">{`Customize point values (between ${STABLEFORD_MAX_POINT} and ${STABLEFORD_MIN_POINT})`}</Typography>
                    <Spacing />
                    <Header />
                    <Divider />
                    {normPoints.map((p, i) => <Row
                        point={p}
                        index={i}
                        key={i}
                        focused={focusedIdx === i}
                        error={focusedIdx !== i && !isValidPoint(p)}
                        appMode={appStablefordSettingsMode}
                        handleFocus={idx => this.setState({ focusedIdx: idx })}
                        handlePoint={this.handlePoint}
                        handleKey={this.handleKey} />)}
                </div>
                <DialogActions>
                    <AppButton color="info" onClick={this.handleClose}>Cancel</AppButton>
                    <AppButton color="secondary" className={classes.iconButton} onClick={this.handleSave} disabled={!isValid}>Save</AppButton>
                </DialogActions>
            </XSMobileDialog>
        );
    }
}

export default withStyles(styles)(StablefordPointSettingsDialog);
