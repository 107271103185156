import * as React from 'react';
import { DialogActions, DialogTitle, DialogContentText, DialogContent } from '@mui/material';
import { importFile } from './Importer';
import { ProgressFunction, showProgress } from '../../redux/ReduxConfig';
import { XSMobileDialog } from '../../common/dialog/MobileDialog';
import AppButton from '../../common/components/AppButton';
import * as Backend from '../../util/firebase';
import { SpreadsheetImportResult } from 'src/types/EventTypes';

type Props = {
    open: boolean;
    importResult: (result: SpreadsheetImportResult, progress?: ProgressFunction) => void;
    handleCancel: () => void;
    maxRecords?: number;
};

type State = {
    templateUrl?: string;
};

class ImportContactDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
        Backend.getDownloadURL(Backend.templateGolferListRef)
            .then(templateUrl => this.setState({ templateUrl }));
    }

    private handleFile = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { maxRecords } = this.props;
        if (!ev.target.files) {
            return;
        }
        const hideProgress = showProgress('importFile');
        importFile(ev.target.files[0], (result) => {
            this.props.importResult(result, hideProgress);
        }, maxRecords);
    }

    render() {
        const { templateUrl } = this.state;
        return <>
            <XSMobileDialog open={this.props.open} onClose={this.props.handleCancel}>
                <DialogTitle>Import from spreadsheet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add multiple golfers from an Excel spreadsheet file. Formats supported: .CSV, .ODS, .XLS or .XLSX. The spreadsheet
                        must include header row with names of the columns (Email, Last Name, First Name...), in any order.<br /><br />
                        {!!templateUrl && <a href={templateUrl}>Download template</a>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <AppButton onClick={this.props.handleCancel} color="info">Cancel</AppButton>
                    <AppButton color="secondary">
                        <label>
                            Choose file
                            <input onChange={this.handleFile} style={{ display: 'none' }} type="file" />
                        </label>
                    </AppButton>
                </DialogActions>
            </XSMobileDialog>
        </>;
    }
}

export default ImportContactDialog;
