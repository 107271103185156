import React from 'react';
import { Divider, Typography, IconButton, List, ListItem, Badge } from '@mui/material';
import { CSVLink } from 'react-csv';
import PeopleIcon from '@mui/icons-material/People';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import { WithStyles } from '@mui/styles';
import { useDrag, useDrop } from 'react-dnd';
import * as Utils from '../../../../util/utility';
import * as Backend from '../../../../util/firebase';
import { withProgress } from '../../../../util/ProgressPromise';
import {
    EventBase, EventData, Contact, Team, ContactDetails, ScoringTeamSize, HandicapMode, GenderMode, Distance, Score, ReportedScore,
    makeNewGroups, golfersOrTeams, golfersOrTeamsPerGroup, SAME_TEAMS_IN_ALL_ROUNDS
} from '../../../../types/EventTypes';
import { fullName, getSameNameGolfersIds } from '../../../../contact/Contact';
import {
    teamName, teamSizeName, addGolferToTeam, removeGolferFromTeam, setEventTeamSize, resetEventTeams, saveEventPairedTeams, elog,
    deleteGolfersFromEvent, saveContact, saveGroupsBatch, getUnpairedGolfersCount, visibleTeamContacts
} from '../../../Event';
import EditTeamsDialog from './EditTeamsDialog';
import { FixedWidthChip } from '../../../../common/components/FixedWidthChip';
import EditContactDialog from '../../../../contact/EditContactDialog';
import AutoScheduleDialog from '../../common/AutoScheduleDialog';
import ButtonBar from '../../../../common/components/ButtonBar';
import AppButton from '../../../../common/components/AppButton';
import LabeledField from '../../../../common/form/LabeledField';
import SelectTeamSizeDialog from '../../common/SelectTeamSizeDialog';
import { ButtonBadge, Container, Item, NoWrapOverflow } from '../../../../common/Misc';
import { styles, useAppStyles } from '../../../../styles';
import { AppColors } from 'src/main/Theme';
import { EmailVariant, sendPaymentMessage } from '../../../../util/email_utils';
import { WithUserAware, useUserAware } from 'src/auth/Auth';

const DRAG_TYPE_TEAM = 'TEAM';

export interface TeamSummary {
    team: Team;
    contacts: Array<Contact>;
    teamSize: number;
    teamFullness: number;
}

interface TeamRowItem {
    name: string;
    golferId: string;
    homeCourseOrCity?: string;
    isPlaceholder: boolean;
    hidden: boolean;
}

interface ContactItemProps {
    name: string;
    golferId: string;
    homeCourseOrCity?: string;
    teamSummary: TeamSummary;
    position: number;
    isTeamOver: boolean;
    isPlaceholder: boolean;
    dropToTeam: (golferId: string, team: Team, position?: number) => void;
    onDelete: () => void;
    onOpen: () => void;
}

interface TeamRowProps {
    golfers: Map<string, Contact>;
    teamSummary: TeamSummary;
    initialItems: Array<TeamRowItem>;
    addToTeam: (team: TeamSummary) => void;
    dropToTeam: (golferId: string, team: Team, position?: number) => void;
    deleteFromTeam: (golferId: string) => void;
    openGolfer: (id: string) => void;
}

function gatherItems(teamSummary: TeamSummary, golfers: Map<string, Contact>): TeamRowItem[] {
    const sameNameGolfersIdsSet: Set<string> = getSameNameGolfersIds(Array.from(golfers.values()));
    const items: TeamRowItem[] = [];
    teamSummary.contacts.map(contact => items.push({
        golferId: contact.id,
        name: fullName(contact),
        homeCourseOrCity: sameNameGolfersIdsSet.has(contact.id) && contact.homeCourseOrCity ? contact.homeCourseOrCity : undefined,
        isPlaceholder: false,
        hidden: !golfers.has(contact.id)
    }));
    return items;
}

function ContactItem(thisItem: ContactItemProps) {
    const classes = useAppStyles();
    const { dropToTeam } = thisItem;
    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: DRAG_TYPE_TEAM,
        item: thisItem,
        collect: monitor => ({ isDragging: monitor.isDragging() })
    }), [thisItem]);
    const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
        accept: DRAG_TYPE_TEAM,
        canDrop: (draggingItem: ContactItemProps) => {
            return !thisItem.isPlaceholder && (thisItem.teamSummary.teamFullness < 1 ||
                !!thisItem.teamSummary.contacts.find(t => t.id === draggingItem.golferId));
        },
        drop: (draggingItem: ContactItemProps) => {
            dropToTeam(draggingItem.golferId, thisItem.teamSummary.team, thisItem.position);
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            item: monitor.getItem() as ContactItemProps
        }),
    }), [thisItem]);
    const label = (
        <NoWrapOverflow>
            <PersonIcon className={classes.textIcon} />{thisItem.name}
            <span className={classes.homeCourseOrCity}>{thisItem.homeCourseOrCity ? ` (${thisItem.homeCourseOrCity})` : ''}
            </span>
        </NoWrapOverflow>
    );
    return (
        <span ref={dragRef}>
            <span ref={dropRef}>
                <span style={{ height: '100%', borderRadius: 16 }}>
                    <FixedWidthChip
                        label={label}
                        color={isOver && canDrop ? 'secondary' : 'default'}
                        style={{ backgroundColor: thisItem.isPlaceholder ? AppColors.webGreen200 : isDragging ? AppColors.webGreen100 : undefined }}
                        onDelete={thisItem.onDelete}
                        onDoubleClick={thisItem.onOpen}
                    />
                </span>
            </span>
        </span>
    );
}

function TeamRow(props: TeamRowProps) {
    const { initialItems, teamSummary, addToTeam, openGolfer, deleteFromTeam, dropToTeam } = props;
    const [{ isOver, canDrop, draggingItem }, dropRef] = useDrop(() => ({
        accept: DRAG_TYPE_TEAM,
        canDrop: () => teamSummary.teamFullness < 1,
        drop: (dragItem: ContactItemProps, monitor) => {
            if (!monitor.didDrop()) {
                console.log(`TeamRow dropToTeam ${dragItem.name} to Team ${teamSummary.team.order}`);
                dropToTeam(dragItem.golferId, teamSummary.team);
            }
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            draggingItem: monitor.getItem() as ContactItemProps
        }),
    }), [props]);
    const classes = useAppStyles();
    const showIcon = teamSummary.teamFullness < 1 && !draggingItem;
    const addHoverItem = draggingItem && canDrop && isOver && draggingItem.teamSummary.team.id !== teamSummary.team.id;
    const itemsToDisplay = initialItems.filter(i => !i.hidden);
    if (addHoverItem) {
        itemsToDisplay.splice(itemsToDisplay.length, 0, { ...draggingItem, hidden: false, isPlaceholder: true });
    }
    const contactsList = itemsToDisplay.map((item, idx) =>
        <ContactItem
            golferId={item.golferId}
            key={item.golferId}
            position={idx}
            name={item.name}
            isPlaceholder={item.isPlaceholder}
            homeCourseOrCity={item.homeCourseOrCity ? item.homeCourseOrCity : undefined}
            isTeamOver={isOver}
            onOpen={() => openGolfer(item.golferId)}
            onDelete={() => deleteFromTeam(item.golferId)}
            dropToTeam={dropToTeam}
            teamSummary={teamSummary}
        />);
    return (
        <div ref={dropRef}>
            <ListItem className={classes.listItem} style={{ backgroundColor: isOver && canDrop ? AppColors.webBlue100 : undefined }}>
                <Container wrap="nowrap">
                    <Item height={38}>
                        <Typography variant="body2" noWrap style={{ width: 64 }}>
                            {teamName(teamSummary.team)}
                        </Typography>
                        {teamSummary.contacts.length > teamSummary.teamSize &&
                            <span style={{ color: 'red', fontSize: '0.65rem' }}>
                                {Utils.withS(teamSummary.contacts.length, 'golfer')}
                            </span>}
                    </Item>
                    <Item wrapLabel>
                        {contactsList}
                        {showIcon &&
                            <IconButton
                                size="large"
                                className={classes.smallIconButton}
                                onClick={() => addToTeam(teamSummary)} >
                                <AddCircleOutlineIcon />
                            </IconButton>}
                    </Item>
                </Container>
            </ListItem >
            <Divider />
        </div>
    );
}

interface State {
    editingTeam: number;
    editedContact?: ContactDetails;
    doAutoPair: boolean;
    openTeamSizeDialog?: boolean;
    handleTeamExport: boolean;
}

type Props = { eventData: EventData; } & WithStyles<typeof styles> & WithUserAware;

class TeamsList extends React.Component<Props, State> {
    state: State = {
        editingTeam: -1,
        doAutoPair: false,
        handleTeamExport: false,
    };

    componentDidMount() {
        Backend.trackEvent('view_teams');
    }

    private handleGolferRowClick = (contact?: ContactDetails) => contact && this.setState({ editedContact: { ...contact } });
    private handleContactDeleted = (golfers: Array<Contact>) => this.deleteGolfersFromEvent(golfers);
    private handleCloseEditDialog = () => this.setState({ editedContact: undefined });
    private handleTeamExport = () => this.setState({ handleTeamExport: true });
    private hideTeamsSizeDialog = () => this.setState({ openTeamSizeDialog: false });
    private showTeamsSizeDialog = () => this.setState({ openTeamSizeDialog: true });

    private eventOrRound() {
        const { event, rounds, selectedRound } = this.props.eventData;
        return (event.type === 'multiday' && SAME_TEAMS_IN_ALL_ROUNDS ? rounds[0] : selectedRound) ?? event;
    }

    private getStaff() {
        const { event, rounds, golfersMap, teamsMap, teamsListMap, groupsMap, competitionsMap } = this.props.eventData;
        const eventOrRound = this.eventOrRound();
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const groups = groupsMap.get(eventOrRound.id) ?? [];
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const teamsList = teamsListMap.get(eventOrRound.id) ?? [];
        const competitions = competitionsMap.get(this.eventOrRound().id) ?? [];
        return { event, rounds, eventOrRound, golfers, teams, teamsList, groups, competitions };
    }

    private handleReset = () => {
        if (SAME_TEAMS_IN_ALL_ROUNDS) {
            const { event, rounds } = this.props.eventData;
            withProgress(resetEventTeams(event, rounds));
        } else {
            withProgress(resetEventTeams(this.eventOrRound()));
        }
    }

    private deleteGolfersFromEvent(golfersToDelete: Array<Contact>) {
        const { event } = this.props.eventData;
        withProgress(deleteGolfersFromEvent(event, golfersToDelete, true));
    }

    private handleContactChanged = (contactDetail: Contact, notificationLess: boolean) => {
        const { event, roster } = this.props.eventData;
        roster.set(contactDetail.id, contactDetail)
        saveContact(event, contactDetail, contactDetail.id ? 'Golfer modified' : 'Golfer added', notificationLess)
            .then(() => this.setState({ editedContact: undefined }));
    }

    private saveTeams = async (teams: Array<Team>, teamsToDel: Array<Team>, genderMode: GenderMode, handicapMode: HandicapMode) => {
        const { event } = this.props.eventData;
        if (SAME_TEAMS_IN_ALL_ROUNDS) {
            const { rounds } = this.props.eventData;
            await withProgress(saveEventPairedTeams(event, rounds, teams, teamsToDel));
        } else {
            await withProgress(saveEventPairedTeams(this.eventOrRound(), [], teams, teamsToDel));
        }
        elog(event, `Teams auto pairing`, `With handicapMode: ${handicapMode} genderMode: ${genderMode}. ${Utils.withS(teams.length, 'new team')} have been paired.`, '');
    }

    private pairTeams = (genderMode: GenderMode, handicapMode: HandicapMode, keepTeams: boolean) => {
        const { eventOrRound, golfers, groups, teamsList } = this.getStaff();
        const visibleGolfers = new Map<string, Contact>();
        golfers.forEach(g => {
            if (!g.hidden) {
                visibleGolfers.set(g.id, g);
            }
        });
        const count = Math.ceil(visibleGolfers.size / eventOrRound.teamSize);
        const teamsFrom = keepTeams ? teamsList : [{ id: '', contactIds: [], order: 0 }];
        const newTeams = makeNewGroups(teamsFrom, eventOrRound.teamSize, count, true, Array.from(golfersOrTeams(visibleGolfers, teamsFrom, true).values()), handicapMode, genderMode);
        const teamsToDel = keepTeams ? [] : teamsList.filter(t1 => t1.id && !newTeams.find(t2 => t2.id === t1.id));
        const autoScheduledGroups = makeNewGroups([], golfersOrTeamsPerGroup(eventOrRound), 0, false, newTeams, 'random', 'random');
        const groupsToDelete = groups.filter(group => group.id && !autoScheduledGroups.find(other => other.id === group.id));
        this.saveTeams(newTeams, teamsToDel, genderMode, handicapMode)
            .then(() => saveGroupsBatch(autoScheduledGroups, genderMode, handicapMode, groupsToDelete, eventOrRound));
    }

    private handlePairTeams = (genderMode: GenderMode, handicapMode: HandicapMode, keepTeams: boolean) =>
        this.setState({ doAutoPair: false }, () => this.pairTeams(genderMode, handicapMode, keepTeams));

    private teamSummary(team: Team, eventOrRound: EventBase): TeamSummary {
        const { golfersMap } = this.props.eventData;
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        const teamSize = eventOrRound.teamSize;
        const contacts = visibleTeamContacts(team, golfers);
        const teamFullness = contacts.length - eventOrRound.teamSize;
        return {
            team,
            teamSize,
            teamFullness,
            contacts
        };
    }

    private saveTeamSize = (teamSize: ScoringTeamSize, oldTeamSize: ScoringTeamSize) => {
        const { event, rounds } = this.props.eventData;
        withProgress(setEventTeamSize(event, rounds, teamSize, oldTeamSize))
            .then(() => this.setState({ openTeamSizeDialog: false }));
    }

    private exportTeamData() {
        const { eventOrRound, golfers, teamsList } = this.getStaff();
        const exportData: string[][] = [];
        const exportHeader = ['Team'];
        for (let i = 1; i <= eventOrRound.teamSize; i += 1) {
            exportHeader.push('Golfer ' + i);
        }
        exportData.push(exportHeader);
        teamsList.forEach((team, index) => {
            const teamContacts = visibleTeamContacts(team, golfers)
            if (teamContacts.length > 0) {
                const exportRow: string[] = [];
                exportRow.push(String(index + 1));
                teamContacts.forEach(contact => exportRow.push(fullName(contact)));
                exportData.push(exportRow);
            }
        });
        return exportData;
    }

    private unpairedGolfers = (eventOrRound: EventBase) => {
        if (eventOrRound.teamSize === 1) {
            return 0;
        }
        const { golfersMap, teamsMap, loadedTeams, loadedGolfers } = this.props.eventData;
        const teams = teamsMap.get(eventOrRound.id) ?? new Map<string, Team>();
        const golfers = golfersMap.get(eventOrRound.id) ?? new Map<string, Contact>();
        return loadedTeams > 0 && loadedGolfers > 0 ? getUnpairedGolfersCount(teams, golfers) : 0;
    }

    private deleteFromTeam = (contactId: string) => {
        const { golfers } = this.getStaff();
        const golferToRemove = golfers.get(contactId);
        if (!golferToRemove) {
            return;
        }
        if (SAME_TEAMS_IN_ALL_ROUNDS) {
            const { event, rounds } = this.props.eventData;
            withProgress(removeGolferFromTeam(event, rounds, golferToRemove));
        } else {
            withProgress(removeGolferFromTeam(this.eventOrRound(), [], golferToRemove));
        }
    }

    private Teams = () => {
        const { classes } = this.props;
        const { event, rounds, selectedRound, setSelectedRound } = this.props.eventData;
        const { handleTeamExport } = this.state;
        const { eventOrRound, golfers, teams, teamsList } = this.getStaff();
        const fileName = event.name.replace(' ', '-') + '-' + Utils.formatDateDashed2(event.date) + (selectedRound ? '-round' + selectedRound.roundOrder : '');
        const exportFile = `${fileName}-teams.csv`;
        const exportData = handleTeamExport ? this.exportTeamData() : '';
        const unpairedGolfers = this.unpairedGolfers(eventOrRound);
        const moveToTeam = (golferId: string, team: Team, position?: number) => {
            const golfer = golfers.get(golferId);
            if (!golfer) return;
            if (SAME_TEAMS_IN_ALL_ROUNDS) {
                withProgress(addGolferToTeam(event, rounds, golfer, team, position));
            } else {
                withProgress(addGolferToTeam(eventOrRound, [], golfer, team, position));
            }
        }
        /*
            DEBUG INFORMATION<br/>
            {Array.from(teams.values()).sort((a, b) => a.order - b.order).map((team, idx) => {
                const teamSummary = this.teamSummary(team, eventOrRound);
                return <pre key={idx}>{team.order} - {team.id} - [{teamSummary.contacts.map(g => fullName(g)).join('] [')}]</pre>
            })}
        */
        return <div className={classes.listRootGrey}>
            DEBUG INFORMATION<br />
            {Array.from(teams.values()).sort((a, b) => a.order - b.order).map((team, idx) => {
                const teamSummary = this.teamSummary(team, eventOrRound);
                return <pre key={idx}>{team.order} - {team.id} - [{teamSummary.contacts.map(g => fullName(g)).join('] [')}]</pre>
            })}
            {(event.type === 'multiday' && !SAME_TEAMS_IN_ALL_ROUNDS) && <ButtonBar margin>
                {rounds.map(round => <AppButton
                    key={round.roundOrder}
                    className={classes.eventRoundButton}
                    color={round.roundOrder === selectedRound?.roundOrder ? 'primary' : 'info'}
                    sx={{ backgroundColor: round.roundOrder === selectedRound?.roundOrder ? undefined : AppColors.white }}
                    onClick={() => setSelectedRound(round)} >
                    Round {round.roundOrder}
                    <ButtonBadge
                        invisible={this.unpairedGolfers(round) === 0}
                        selected={round.roundOrder === selectedRound?.roundOrder} />
                </AppButton>)}
            </ButtonBar>}
            <List disablePadding className={SAME_TEAMS_IN_ALL_ROUNDS ? classes.listRootGrey : classes.listRootGreySmallVert}>
                <LabeledField label={'Teams'} itemClass={classes.listItem} value={teamSizeName(eventOrRound.teamSize)} edit={this.showTeamsSizeDialog} />
            </List>
            {eventOrRound.teamSize > 1 && <List disablePadding className={SAME_TEAMS_IN_ALL_ROUNDS ? classes.listRootGrey : classes.listRootGreySmallVert}>
                <ListItem className={classes.listItem}>
                    <ButtonBar margin>
                        <AppButton color="secondary" onClick={() => this.setState({ doAutoPair: true })}>
                            <PeopleIcon className={classes.leftButtonIcon} />Auto pair...
                        </AppButton>
                        {<CSVLink data={exportData} filename={exportFile} style={{ textDecoration: 'none' }}>
                            <AppButton color="info" onClick={this.handleTeamExport}>Export</AppButton>
                        </CSVLink>}
                        <AppButton color="info" onClick={this.handleReset}>Reset all</AppButton>
                        {unpairedGolfers > 0 && <Typography variant="body1" style={{ marginLeft: 16 }}>
                            {Utils.withS(unpairedGolfers, 'golfer')} not paired yet
                            <Badge sx={{ marginBottom: 2, marginLeft: 1 }} color="error" variant="dot" overlap="rectangular" />
                        </Typography>}
                    </ButtonBar>
                </ListItem>
                {teamsList.map((team, idx) => {
                    const teamSummary = this.teamSummary(team, eventOrRound);
                    return <TeamRow
                        key={idx}
                        golfers={golfers}
                        teamSummary={teamSummary}
                        initialItems={gatherItems(teamSummary, golfers)}
                        addToTeam={team => this.setState({ editingTeam: team.team.order })}
                        dropToTeam={moveToTeam}
                        openGolfer={id => this.handleGolferRowClick(golfers.get(id))}
                        deleteFromTeam={this.deleteFromTeam} />
                })}
            </List>}
        </div>;
    }

    private handleSendMessage = async (contactsToSend: Contact[], variant: EmailVariant) => {
        const { eventData } = this.props;
        await sendPaymentMessage(contactsToSend, eventData, new Map<string, Score>(), new Map<string, Score>(),
            new Map<string, ReportedScore>(), new Map<string, ReportedScore>(), new Map<string, Distance>(), variant);
    };

    render() {
        const { eventData, userAware } = this.props;
        const { event, rounds, loadedTeams, loadedGolfers } = eventData;
        const { eventOrRound, golfers, teamsList, competitions } = this.getStaff();
        const { editedContact, doAutoPair, openTeamSizeDialog, editingTeam } = this.state;
        return <>
            <this.Teams />
            {!!editedContact && <EditContactDialog
                open
                event={event}
                rounds={rounds}
                hasPro={!!userAware.hasPro}
                actionMode={editedContact.id ? 'edit' : 'add'}
                initialContact={editedContact}
                saveToEvent={this.handleContactChanged}
                handleClose={this.handleCloseEditDialog}
                sendEmail={this.handleSendMessage}
                deleteFromEvent={this.handleContactDeleted} />}
            {editingTeam >= 0 && <EditTeamsDialog
                event={event}
                rounds={rounds}
                eventOrRound={eventOrRound}
                golfers={golfers}
                teamsList={teamsList}
                loadedTeams={loadedTeams}
                loadedGolfers={loadedGolfers}
                editingTeam={editingTeam}
                handleClose={() => this.setState({ editingTeam: -1 })} />}
            {doAutoPair && <AutoScheduleDialog
                open
                pairing
                eventOrRound={eventOrRound}
                close={() => this.setState({ doAutoPair: false })}
                save={this.handlePairTeams}
                golfersOrTeams={golfersOrTeams(golfers, teamsList, true)}
                groups={teamsList}
                count={Math.ceil(golfers.size / eventOrRound.teamSize)}
                groupSize={eventOrRound.teamSize} />}
            {openTeamSizeDialog && <SelectTeamSizeDialog
                teamSize={eventOrRound.teamSize}
                saveTeamSize={this.saveTeamSize}
                close={this.hideTeamsSizeDialog}
                competitions={competitions} />}
        </>;
    }
}

export default function(props: Omit<Props, 'classes' | 'userAware'>) {
    const classes = useAppStyles();
    const userAware = useUserAware();
    return <TeamsList classes={classes} userAware={userAware} {...props} />;
}
