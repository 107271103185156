import { DialogContent } from '@mui/material';
import * as React from 'react';
import AccountPlans from 'src/auth/AccountPlans';
import { AccountActions } from 'src/auth/StripeRedirect';
import GolfpadEventsLogo from 'src/common/Logo';
import DialogAppBar from 'src/common/dialog/DialogAppBar';
import { SMMobileDialog } from 'src/common/dialog/MobileDialog';

type Props = {
    handleClose: (name?: AccountActions) => void;
};

export default function ProPlanDialog(props: Props) {
    const { handleClose } = props;
    return (
        <SMMobileDialog open onClose={() => handleClose()} maxWidth={'lg'} fullWidth>
            <DialogAppBar bar close={() => handleClose()} label={<GolfpadEventsLogo hasPro />} toolbarBarStyle={{ padding: 0, marginLeft: 16, marginRight: 16 }} />
            <DialogContent sx={{ padding: 0, margin: 0 }}>
                <AccountPlans variant="short" action={handleClose}/>
            </DialogContent>
        </SMMobileDialog>
    );
}
