import * as React from 'react';
import { ProgressFunction, showError, showProgress } from '../redux/ReduxConfig';
import {
    Entity, toArray, Query, CollectionReference, DocumentReference, DocumentData, DocumentSnapshot,
    firebaseAuth, userFields, userPubFields, onSnapshot, QueryConstraint, startAfter, query, getDocs, limit, toMap
} from '../util/firebase';
import { Func, dbgLog } from 'src/util/utility';

type FirebaseDocProps = {
    docReference: DocumentReference;
    onData?: (data: DocumentData) => void;
    onDoc?: (doc: DocumentSnapshot) => void;
    delay?: number;
};

export class FirebaseDocListener {
    private unsubscribe?: Func;
    private hideProgress?: ProgressFunction;
    constructor(props: FirebaseDocProps) {
        const { docReference, onData, onDoc, delay } = props;
        this.hideProgress = showProgress('FirebaseDocListener ' + docReference.path);
        this.unsubscribe = onSnapshot(docReference, doc => setTimeout(() => {
            if (this.hideProgress) {
                this.hideProgress();
                this.hideProgress = undefined;
            }
            if (onDoc) {
                onDoc(doc);
            }
            if (onData) {
                onData((doc.exists() && doc.data()) || {});
            }
        }, delay ? delay : 0), err => {
            if (this.hideProgress) {
                this.hideProgress();
                this.hideProgress = undefined;
            }
            showError('Failed to request doc data for "' + docReference.id + '" due to error: ' + err.message);
        });
    }
    public cleanup = () => {
        if (this.hideProgress) {
            this.hideProgress();
            this.hideProgress = undefined;
        }
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = undefined;
        }
    }
}

export class FirebaseDocComponent extends React.Component<FirebaseDocProps> {
    private firebaseDocListener?: FirebaseDocListener;
    componentDidMount() {
        this.firebaseDocListener = new FirebaseDocListener(this.props);
    }
    componentWillUnmount() {
        if (this.firebaseDocListener) {
            this.firebaseDocListener.cleanup();
        }
    }
    render() {
        return null;
    }
}

export class FirebaseUserDataComponent extends React.Component<{ onData: (data: DocumentData) => void }> {
    render() {
        const { onData } = this.props;
        const uid = firebaseAuth.currentUser?.uid;
        if (!uid) {
            return null;
        }
        return (<FirebaseDocComponent docReference={userFields(uid)} onData={onData} />);
    }
}

export class FirebaseUserPubDataComponent extends React.Component<{ uid: string, onData: (data: DocumentData) => void }> {
    render() {
        const { uid, onData } = this.props;
        if (!uid) {
            return null;
        }
        return (<FirebaseDocComponent docReference={userPubFields(uid)} onData={onData} />);
    }
}

type FirebaseDataProps<Item extends Entity> = {
    query: Query;
    queryPath?: string;
    onData?: (arr: Array<Item>) => void;
    onMap?: (map: Map<string, Item>) => void;
    onDataMap?: (arr: Array<Item>, map: Map<string, Item>) => void;
    component?: React.Component;
    name?: string;
    delay?: number;
    dataArray?: string;
    dataMap?: string;
    dbg?: boolean;
};

export function queryPath(query: Query) {
    return (query as any)._path?.segments?.join()
}

export class FirebaseDataListener<Item extends Entity> {
    private unsubscribe: Func;
    private t = Date.now();
    private hideInitialProgress: Func | undefined = undefined;
    constructor(p: FirebaseDataProps<Item>) {
        this.unsubscribe = this.start(p);
    }
    public logd = (p: FirebaseDataProps<Item>, ...msg: Array<any>) => {
        if (p.dbg) {
            const t = Date.now();
            dbgLog('[FirebaseDataListener]', p.name, p.queryPath, 'from start:', t - this.t, ...msg);
            this.t = t;
        }
    }
    public update = (p: FirebaseDataProps<Item>, pp: FirebaseDataProps<Item>) => {
        if (p.queryPath !== pp.queryPath) {
            if (this.unsubscribe) {
                this.unsubscribe();
            }
            this.unsubscribe = this.start(p);
        }
    }
    public cleanup = () => {
        if (this.unsubscribe) {
            this.unsubscribe();
            this.unsubscribe = () => { };
        }
        if (this.hideInitialProgress) {
            this.hideInitialProgress();
            this.hideInitialProgress = undefined;
        }
    }
    private start = (p: FirebaseDataProps<Item>) => {
        this.logd(p, 'start...');
        this.hideInitialProgress = showProgress('FirebaseDataListener ' + (p.name ?? ''));
        const f = p.delay ? (f: Func) => setTimeout(f, p.delay) : (f: Func) => f();
        return onSnapshot(p.query, snapshot => f(() => {
            this.logd(p, 'snapshot:', snapshot.docs.length);
            const dataArray = toArray<Item>(snapshot);
            const dataMap = new Map<string, Item>();
            if (p.onMap || p.onDataMap || (p.component && p.dataMap)) {
                dataArray.forEach(item => dataMap.set(item.id, item));
            }
            if (p.onData) {
                p.onData(dataArray);
            }
            if (p.onMap) {
                p.onMap(dataMap);
            }
            if (p.onDataMap) {
                p.onDataMap(dataArray, dataMap);
            }
            if (p.component) {
                const newState = {} as any;
                if (p.dataArray) {
                    newState[p.dataArray] = dataArray;
                }
                if (p.dataMap) {
                    newState[p.dataMap] = dataMap;
                }
                p.component.setState(newState);
            }
            if (this.hideInitialProgress) {
                this.hideInitialProgress();
                this.hideInitialProgress = undefined;
            }
        }), err => {
            showError('Failed to request data due to error: ' + err.message + (p.name ? ` [${p.name}]: ` : ': ') + (p.queryPath ?? ''));
            if (this.hideInitialProgress) {
                this.hideInitialProgress();
                this.hideInitialProgress = undefined;
            }

        });
    }
}

export class FirebaseDataComponent<Item extends Entity> extends React.Component<FirebaseDataProps<Item>> {
    private firebaseDataListener?: FirebaseDataListener<Item>;
    state = { value: '' };
    componentDidMount() {
        this.firebaseDataListener = new FirebaseDataListener<Item>(this.props);
    }
    componentDidUpdate(prevProps: Readonly<FirebaseDataProps<Item>>) {
        if (this.firebaseDataListener) {
            this.firebaseDataListener.update(this.props, prevProps);
        }
    }
    componentWillUnmount() {
        if (this.firebaseDataListener) {
            this.firebaseDataListener.cleanup();
        }
    }
    render() {
        return null;
    }
}

export type PaginationProps<Item extends Entity> = {
    collection: CollectionReference<DocumentData, DocumentData>;
    queryConstraints: QueryConstraint[];
    onData?: (arr: Array<Item>) => void;
    onMap?: (map: Map<string, Item>) => void;
    loadLimit: number; // don't set more, than 30 (Firebase 'in' param specific in 'where' operator)
};

export class FirebasePaginatedDataProvider<Item extends Entity> {
    private readonly props: PaginationProps<Item>;

    private lastDocument?: DocumentSnapshot;

    constructor(props: PaginationProps<Item>) {
        this.props = props;
    }

    private constructQuery = (lim?: number): Query => {
        const { lastDocument, props } = this;
        const { loadLimit } = this.props;
        if (lim && lim > loadLimit) lim = loadLimit;
        const additionalConstraints: Array<QueryConstraint> = [];
        if (lastDocument) {
            additionalConstraints.push(startAfter(lastDocument));
        }
        additionalConstraints.push(limit(lim ?? loadLimit));
        const constraints = props.queryConstraints.concat(...additionalConstraints);
        return query(props.collection, ...constraints);
    };

    public loadNextPortion = async (limit?: number): Promise<void> => {
        const { props, constructQuery } = this;
        const hideProgress = showProgress('loadNextPortionS');
        const query = constructQuery(limit);
        dbgLog(`loadNextPortion ${limit} lastDocument=${this.lastDocument?.id}`);
        const querySnapshot = await getDocs(query);
        hideProgress();
        if (props.onData) {
            const dataArray = toArray<Item>(querySnapshot);
            props.onData(dataArray);
        }
        if (props.onMap) {
            const dataMap = toMap<Item>(querySnapshot);
            props.onMap(dataMap);
        }
        this.lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
        dbgLog(`loadNextPortion loaded ${querySnapshot.size} docs, lastDocument=${this.lastDocument?.id}`);
    };
}
